import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export type Media = {
  _id: number;
  createdAt: string;
  fileName: string;
  isFullMedia: boolean;
  link: string;
  trackID: number;
  type: string;
  active: boolean;
};

export type GetMediaResponse = {
  media: Media[];
  pageInfo: { total: number; ofset: number; hasNextPage: boolean };
};

export type UpdateMediaBody = {
  trackID: number;
  _id: number;
} & Partial<Media>;

export type UpdateMediaResponse = Media;

export const updateMedia = async (
  { _id, ...body }: UpdateMediaBody,
  options: FetcherRequestConfig
): Promise<UpdateMediaResponse> => {
  const result = await fetcher<UpdateMediaResponse>(
    `/tracks/${body.trackID}/media/${_id}`,
    {
      ...options,
      method: HTTPMethod.PUT,
      body,
    }
  );
  const response = await result.json();
  return response;
};

export type CreateMediaBody = Omit<Media, '_id' | 'createdAt'>;

export type CreateMediaResponse = null;

export const createMedia = async (
  { trackID, ...body }: CreateMediaBody,
  options: FetcherRequestConfig
): Promise<CreateMediaResponse> => {
  const result = await fetcher<CreateMediaResponse>(
    `/tracks/${trackID}/media`,
    {
      ...options,
      method: HTTPMethod.POST,
      body,
    }
  );
  const response = await result.json();
  return response;
};

export type DeleteMediaBody = {
  _id: number;
  trackID: number;
};

export type DeleteMediaResponse = null;

export const deleteMedia = async (
  { _id, trackID }: DeleteMediaBody,
  options: FetcherRequestConfig
): Promise<DeleteMediaResponse> => {
  await fetcher<DeleteMediaResponse>(`/tracks/${trackID}/media/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

export type GetMediaDownloadLinkResponse = string;
export type GetMediaDownloadLinkBody = {
  _id: number;
  trackID: number;
};

export const getMediaDownloadLink = async (
  { _id, trackID }: UpdateMediaBody,
  options: FetcherRequestConfig
): Promise<GetMediaDownloadLinkResponse> => {
  const result = await fetcher<GetMediaDownloadLinkResponse>(
    `/tracks/${trackID}/media/${_id}/download`,
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const response = await result.text();
  return response;
};

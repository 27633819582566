import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useCreatePlanApiCall } from '../../../../api/plans/hooks';
import { PlanLevel, CreatePlanBody, Plan } from '../../../../api/plans';

interface Props {
  handleModalClose: VoidFunction;
  onCreatePlan: VoidFunction;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: { flexWrap: 'wrap' },
    input: {
      margin: theme.spacing(3, 0),
      width: '15vw',
    },
    textInput: {
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  })
);

export default function NewPlanForm(props: Props) {
  const classes = useStyles();
  const createResult = useCreatePlanApiCall();
  const [intervalChange, setIntervalChange] = useState<'year' | 'month'>();
  const [autoRenewChange, setAutoRenewChange] = useState<boolean>(false);
  const [planAmount, setPlanAmount] = useState<number>(0);
  const [accessLevelChange, setAccessLevelChange] = useState<PlanLevel>();
  const [planName, setPlanName] = useState<string>('');
  const [errors, setErrors] = useState({
    planNameError: '',
    planAmountError: '',
    planIntervalError: '',
    planAccessLevelError: '',
  });

  const handlePlanName = (event: any) => {
    setPlanName(event.target.value);
  };
  const handlePlanAmountChange = (event: any) => {
    setPlanAmount(event.target.value);
  };
  const handleIntervalChange = (event: any) => {
    setIntervalChange(event.target.value);
  };
  const handleAccessLevel = (event: any) => {
    setAccessLevelChange(event.target.value);
  };
  const handleAutoRenew = (event: any) => {
    setAutoRenewChange(event.target.checked);
  };
  const handleCreatePlan = (createPlanBody: CreatePlanBody) => {
    const isValid = validate(
      createPlanBody.name,
      createPlanBody.amount,
      createPlanBody.interval,
      createPlanBody.metadata.level
    );
    if (isValid) {
      createResult
        .runApiCall(createPlanBody)
        .then(props.handleModalClose)
        .then(() => props.onCreatePlan())
        .catch((error) => console.error(error));
    }
  };

  const validate = (
    planName: string,
    planAmount: number,
    planInterval?: string,
    planAccessLevel?: PlanLevel
  ) => {
    const charMin = 1;
    const charMax = 50;
    let planNameError = '';
    let planAmountError = '';
    let planIntervalError = '';
    let planAccessLevelError = '';

    if (planName.length < charMin || planName.length >= charMax) {
      planNameError = 'Coupon name must be between 1 and 50 characters';
    }
    if (planAmount <= 0 || planAmount == undefined) {
      planAmountError = 'Plan amount must be a value greater than $0';
    }
    if (planInterval == undefined) {
      planIntervalError = 'You must select an interval';
    }
    if (planAccessLevel == undefined) {
      planAccessLevelError = 'You must select an access level';
    }

    if (
      planNameError ||
      planAmountError ||
      planIntervalError ||
      planAccessLevelError
    ) {
      setErrors({
        planNameError,
        planAmountError,
        planIntervalError,
        planAccessLevelError,
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <DialogTitle>Add a New Plan</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" alignItems="center" justify="center">
          <FormControl
            className={classes.input}
            error={errors.planNameError ? true : false}
          >
            <InputLabel className={classes.textInput}>Plan Name</InputLabel>
            <OutlinedInput
              error={errors.planNameError ? true : false}
              autoFocus
              required={true}
              id="coupon"
              type="string"
              value={planName}
              onChange={handlePlanName}
            />
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.planNameError}
          </Typography>

          <FormControl
            className={classes.input}
            error={errors.planAmountError ? true : false}
          >
            <InputLabel className={classes.textInput}>Amount</InputLabel>
            <OutlinedInput
              id="amount"
              type="number"
              error={errors.planAmountError ? true : false}
              value={planAmount}
              onChange={handlePlanAmountChange}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.planAmountError}
          </Typography>
          <FormControl
            variant="outlined"
            className={classes.input}
            error={errors.planIntervalError ? true : false}
          >
            <InputLabel>Interval</InputLabel>
            <Select
              id="interval"
              error={errors.planIntervalError ? true : false}
              value={intervalChange}
              onChange={handleIntervalChange}
              label={'Interval'}
            >
              <MenuItem value={'year'}>Year</MenuItem>
              <MenuItem value={'month'}>Month</MenuItem>
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.planIntervalError}
          </Typography>

          <FormControl
            variant="outlined"
            className={classes.input}
            error={errors.planAccessLevelError ? true : false}
          >
            <InputLabel>Access Level</InputLabel>
            <Select
              id="accessLevel"
              value={accessLevelChange}
              error={errors.planAccessLevelError ? true : false}
              onChange={handleAccessLevel}
              label={'Access Level'}
            >
              <MenuItem value={PlanLevel.SOCIAL}>Social</MenuItem>
              <MenuItem value={PlanLevel.SMALL_BUSINESS}>
                Small Business
              </MenuItem>
              <MenuItem value={PlanLevel.PREMIUM}>Premium</MenuItem>
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.planAccessLevelError}
          </Typography>
          <FormControlLabel
            className={classes.input}
            checked={autoRenewChange}
            onChange={handleAutoRenew}
            control={<Checkbox color="primary" />}
            label="Auto Renew Plan"
            labelPlacement="end"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleCreatePlan({
                amount: planAmount * 100,
                interval: intervalChange,
                metadata: {
                  level: accessLevelChange,
                  autoRenew: autoRenewChange ? 'true' : 'false',
                },
                name: planName,
                // cost: 'planAmount'
              })
            }
          >
            Submit
          </Button>
          <Typography color="error">{createResult.error}</Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={props.handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

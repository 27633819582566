import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useGetCouponsApiCall,
  useDeleteCouponApiCall,
} from '../../../../api/coupons/hooks';
import NewCouponForm from './NewCouponForm';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Dialog,
} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import { Coupon, DeleteCouponBody } from '../../../../api/coupons';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
  })
);

export default function Coupons() {
  const classes = useStyles();
  const result = useGetCouponsApiCall();
  const deleteResult = useDeleteCouponApiCall();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleDeleteCoupon = (deleteCouponBody: DeleteCouponBody) => {
    deleteResult.runApiCall(deleteCouponBody).then(() => result.refetch());
  };

  const handleModalOpen = () => {
    setCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Coupons
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={result.loading}
        onClick={handleModalOpen}
      >
        Add New
      </Button>
      <Dialog
        open={createModalOpen}
        onClose={handleModalClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <NewCouponForm
          handleModalClose={handleModalClose}
          onCreateCoupon={result.refetch}
        />
      </Dialog>
      <div className={classes.mainContent}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            {result.loading ? (
              <CircularProgress />
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>ID</TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      % Off
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Amount Off
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Discount Duration
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Times Redeemed
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data?.map((dataReceived: Coupon, key) => (
                    <TableRow
                      key={dataReceived.id}
                      className={key % 2 ? classes.tableRow : classes.table}
                    >
                      <TableCell>{dataReceived.id}</TableCell>

                      <TableCell align="right">
                        {dataReceived.percent_off
                          ? dataReceived.percent_off + '%'
                          : dataReceived.percent_off}
                      </TableCell>
                      <TableCell align="right">
                        {dataReceived.amount_off
                          ? '$ ' + dataReceived.amount_off / 100
                          : dataReceived.amount_off}
                      </TableCell>
                      <TableCell align="right">
                        {dataReceived.duration_in_months
                          ? dataReceived.duration_in_months + ' months'
                          : dataReceived.duration}
                      </TableCell>
                      <TableCell align="right">
                        {dataReceived.times_redeemed}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            handleDeleteCoupon({ id: dataReceived.id })
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Tag, TagCategory, TAG_CATEGORIES } from '../api/tags';
import { AppRoutes } from '../constants/routes';

export type SearchSection = TagCategory | 'Collections';

type SearchContextReturn = {
  tags: Tag[];
  searchTextItems: string[];
  fullScreenSearchOpen: boolean;
  onCloseFullScreenSearch: VoidFunction;
  onOpenFullScreenSearch: VoidFunction;
  onUpdateTags: (tags: Tag[]) => void;
  onUpdateSearchTextItems: (searchTextItems: string[]) => void;
  selectedSection: SearchSection;
  onSelectSection: (section: SearchSection) => void;
};

export const SearchContext = createContext<SearchContextReturn>({
  tags: [],
  searchTextItems: [],
  onUpdateTags: () => {},
  fullScreenSearchOpen: false,
  onCloseFullScreenSearch: () => {},
  onOpenFullScreenSearch: () => {},
  onUpdateSearchTextItems: () => {},
  selectedSection: 'Collections',
  onSelectSection: (section: SearchSection) => {},
});

export const useSearchState = (): SearchContextReturn => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [searchTextItems, setSearchTextItems] = useState<string[]>([]);
  const [fullScreenSearchOpen, setFullScreenSearchOpen] = useState<boolean>(
    false
  );
  const [selectedSection, setSelectedSection] = useState<SearchSection>(
    'Collections'
  ); //TODO: Update this to be pulled from the url
  const history = useHistory();
  return {
    tags,
    onUpdateTags: setTags,
    searchTextItems,
    onUpdateSearchTextItems: setSearchTextItems,
    fullScreenSearchOpen,
    onCloseFullScreenSearch: () => setFullScreenSearchOpen(false),
    onOpenFullScreenSearch: () => setFullScreenSearchOpen(true),
    selectedSection,
    onSelectSection: setSelectedSection,
  };
};

export const useSearch = () => {
  return useContext(SearchContext);
};

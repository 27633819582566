import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export enum CouponDuration {
  FOREVER = 'forever',
  ONCE = 'once',
  REPEATING = 'repeating',
}

export type Coupon = {
  id: string;
  times_redeemed: number;
  percent_off: number;
  amount_off: number;
  duration: CouponDuration;
  duration_in_months: number | null;
};

export type GetCouponsResponse = Coupon[];

export const getCoupons = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetCouponsResponse> => {
  const result = await fetcher<GetCouponsResponse>(`/stripe/coupons`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type GetCouponBody = { code: string };
export type GetCouponResponse = Coupon;

export const getCoupon = async (
  { code }: GetCouponBody,
  options: FetcherRequestConfig
): Promise<GetCouponResponse> => {
  const result = await fetcher<GetCouponResponse>(
    `/stripe/coupons/${encodeURIComponent(code)}`,
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const response = await result.json();
  return response;
};

export type CreateCouponBody = {
  duration: CouponDuration;
  duration_in_months?: number;
  id: string;
  metadata: {};
  percent_off?: number;
  amount_off?: number;
};

export type CreateCouponResponse = Coupon;

export const createCoupon = async (
  body: CreateCouponBody,
  options: FetcherRequestConfig
): Promise<CreateCouponResponse> => {
  const result = await fetcher<CreateCouponResponse>('/stripe/coupons', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type DeleteCouponBody = {
  id: string | number;
};

export type DeleteCouponResponse = {};

export const deleteCoupon = async (
  { id }: DeleteCouponBody,
  options: FetcherRequestConfig
): Promise<DeleteCouponResponse> => {
  await fetcher<DeleteCouponResponse>(`/stripe/coupons/${id}`, {
    method: HTTPMethod.DELETE,
    ...options,
  });
  return {};
};

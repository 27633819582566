import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export type SiteConfig = {
  _id: number;
  createdAt: string;
  info: {
    highEndMusicCollectionID: number;
    stockMusicProBundleCollectionID: number;
    videographerCollectionID: number;
    noCreditCardRequired: boolean;
  };
  active: boolean;
};

export type GetSiteConfigsResponse = SiteConfig[];

export const getSiteConfigs = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetSiteConfigsResponse> => {
  const result = await fetcher<GetSiteConfigsResponse>(`/siteConfigs`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type UpdateSiteConfigBody = {
  info: Partial<SiteConfig['info']>;
};

export type UpdateSiteConfigResponse = null;

export const updateSiteConfig = async (
  body: UpdateSiteConfigBody,
  options: FetcherRequestConfig
): Promise<UpdateSiteConfigResponse> => {
  const result = await fetcher<UpdateSiteConfigResponse>(`/siteConfigs`, {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = null;
  return response;
};

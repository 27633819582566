import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { Button, Chip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Category } from '../../api/categories';
import { useIsMobile } from '../../hooks/isMobile';
const CATEGORY_HEIGHT = 210;

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      '& > *:first-child': {
        marginBottom: theme.spacing(2),
        fontWeight: 'light',
        marginLeft: theme.spacing(1),
      },
    },
    active: { border: `1px solid ${theme.palette.common.white}` },
    title: {
      height: '25%',
      padding: theme.spacing(1),
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: '#484143',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    category: {
      height: CATEGORY_HEIGHT,
      minWidth: 168,
      width: 168,
      borderRadius: 8,
      position: 'relative',
      cursor: 'pointer',
    },
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': { margin: theme.spacing(2), backgroundSize: 'cover' },
      maxHeight: CATEGORY_HEIGHT + theme.spacing(2),
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto',
        flexWrap: 'unset',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    showAll: {
      maxHeight: 'unset',
    },
    separator: {
      border: `2px solid ${theme.palette.common.white}`,
    },
  })
);

interface Props {
  onSelectCategory: (category: Category) => void;
  categories: Category[];
  selectedCategory: Category | null;
}

export const CategoryPicker = ({
  categories,
  onSelectCategory,
  selectedCategory,
}: Props) => {
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();
  const handleSelectCategory = (category: Category) => {
    setShowAll(false);
    onSelectCategory(category);
  };
  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <Typography variant="h5">Collections</Typography>
      {!isMobile && (
        <div className={classes.header}>
          <div></div>
          <Button onClick={handleToggleShowAll} variant="text">
            {showAll ? 'Show less' : 'Show All'}
          </Button>
        </div>
      )}
      <div className={clsx(classes.list, showAll && classes.showAll)}>
        {selectedCategory !== null && (
          <>
            <CategoryCard
              classes={classes}
              key={selectedCategory._id}
              active={true}
              onClick={handleSelectCategory}
              category={selectedCategory}
            />
            <div className={classes.separator} />
          </>
        )}
        {categories
          .filter((c) => c._id !== selectedCategory?._id)
          .map((category) => (
            <CategoryCard
              classes={classes}
              key={category._id}
              active={category._id === selectedCategory?._id}
              onClick={handleSelectCategory}
              category={category}
            />
          ))}
      </div>
    </div>
  );
};

const CategoryCard = ({
  classes,
  category,
  onClick,
  active,
}: {
  classes: any;
  category: Category;
  onClick: (category: Category) => void;
  active: boolean;
}) => (
  <div
    key={category._id}
    className={clsx(classes.category, active && classes.active)}
    onClick={() => onClick(category)}
    style={{ backgroundImage: `url(${category.detailImageURL})` }}
  >
    <div className={classes.title}>
      <Typography>{category.name}</Typography>
    </div>
  </div>
);

import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';
import { Track } from '../tracks';
import { Category } from '../categories';
export type FavoriteTrack = {
  _id: number;
  createdAt: string;
  userID: number;
  trackID: number;
  info?: {};
  Track: Track & { category: Category };
};

export type GetFavoriteTracksResponse = FavoriteTrack[];
export type GetFavoriteTracksBody = { userID?: number };

export const getFavoriteTracks = async (
  { userID }: GetFavoriteTracksBody,
  options: FetcherRequestConfig
): Promise<GetFavoriteTracksResponse> => {
  const result = await fetcher<GetFavoriteTracksResponse>(
    `/favoriteTracks${userID ? '/' + userID : ''}`,
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const response = await result.json();
  return response;
};

export type CreateFavoriteTrackBody = { trackID: number };

export type CreateFavoriteTrackResponse = FavoriteTrack;

export const createFavoriteTrack = async (
  body: CreateFavoriteTrackBody,
  options: FetcherRequestConfig
): Promise<CreateFavoriteTrackResponse> => {
  const result = await fetcher<CreateFavoriteTrackResponse>('/favoriteTracks', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  return await result.json();
};

export type DeleteFavoriteTrackBody = {
  _id: number;
};

export type DeleteFavoriteTrackResponse = null;

export const deleteFavoriteTrack = async (
  { _id }: DeleteFavoriteTrackBody,
  options: FetcherRequestConfig
): Promise<DeleteFavoriteTrackResponse> => {
  await fetcher<DeleteFavoriteTrackResponse>(`/favoriteTracks/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useCreateFavoriteTrackApiCall,
  useDeleteFavoriteTrackApiCall,
} from '../../api/favoriteTracks/hooks';
import { Track } from '../../api/tracks';
import { AppRoutes } from '../../constants/routes';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { FavoriteButton, ShareButton } from '../IconButtons/IconButtons';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
  })
);
export interface ShareTrackModal {
  track?: Track | null;
  onClose: VoidFunction;
  open: boolean;
}

export const ShareTrackModal = ({ track, onClose, open }: ShareTrackModal) => {
  const classes = useStyles();
  const handleFocus = (event: any) => event.target.select();

  return (
    <Dialog
      open={Boolean(track && open)}
      onClose={onClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>Share: {track?.name}</DialogTitle>
      <DialogContent>
        <TextField
          onFocus={handleFocus}
          fullWidth={true}
          value={`https://pleasantpictures.club/?track=${
            track?._id
          }&name=${encodeURIComponent(track?.name || '')}`}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useGetPackCodesApiCall,
  useDeletePackCodeApiCall,
} from '../../../../api/packCodes/hooks';
import NewPackCodeForm from './NewPackCodeForm';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Dialog,
} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import { DeletePackCodeBody, PackCode } from '../../../../api/packCodes';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
  })
);

export default function Plans() {
  const classes = useStyles();
  const result = useGetPackCodesApiCall();
  const deleteResult = useDeletePackCodeApiCall();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleDeletePackCode = (deletePackCodeBody: DeletePackCodeBody) => {
    deleteResult.runApiCall(deletePackCodeBody).then(() => result.refetch());
  };

  const handleModalOpen = () => {
    setCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Pack Codes
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={result.loading}
        onClick={handleModalOpen}
      >
        Add New
      </Button>
      <Dialog
        open={createModalOpen}
        onClose={handleModalClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <NewPackCodeForm
          handleModalClose={handleModalClose}
          onCreatePlan={result.refetch}
        />
      </Dialog>
      <div className={classes.mainContent}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            {result.loading ? (
              <CircularProgress />
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      Pack Code Name
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Bundle
                    </TableCell>
                    <TableCell align="center" className={classes.tableHeader}>
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data?.map((dataReceived: PackCode, key) => (
                    <TableRow
                      key={dataReceived._id}
                      className={key % 2 ? classes.tableRow : classes.table}
                    >
                      <TableCell>{dataReceived.code}</TableCell>
                      <TableCell align="center">
                        {dataReceived.bundle}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() =>
                            handleDeletePackCode({ _id: dataReceived._id })
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

import { createMedia, deleteMedia, getMediaDownloadLink, updateMedia } from '.';
import { useActionApiCall } from '../useActionApiCall';
import { UseApiOptions } from '../useApiCall';

export const useCreateMediaApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createMedia,
    ...options,
  });
  return result;
};

export const useUpdateMediaApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: updateMedia, ...options });
  return result;
};

export const useDeleteMediaApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteMedia, ...options });
  return result;
};

export const useGetMediaDownloadLink = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: getMediaDownloadLink,
    ...options,
  });
  return result;
};

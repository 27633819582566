import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { Track } from '../../api/tracks';
import { Button, ButtonProps, IconButton, Typography } from '@material-ui/core';
import { PlayCircleFilledOutlined } from '@material-ui/icons';
import { PauseButton, PlayButton } from '../IconButtons/IconButtons';
import {
  LIBRARY_SUBSCRIPTION_PRICE,
  TRACK_PURCHASE_PRICE,
} from '../../constants/values';
import { AppRoutes } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { DownloadTrackDialog } from '../DownloadTrackDialog/DownloadTrackDialog';

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

export const EntireLibraryButton = (props: ButtonProps) => {
  const classes = useStyles();
  return (
    <Link to={AppRoutes.PLANS}>
      <Button size="small" variant="contained" color="primary" {...props}>
        Entire Library for ${LIBRARY_SUBSCRIPTION_PRICE}/yr
      </Button>
    </Link>
  );
};

export const PurchaseTrackButton = (props: ButtonProps & { track: Track }) => {
  const classes = useStyles();
  return (
    <Link to={AppRoutes.PURCHASE_TRACK + `/${props.track._id}`}>
      <Button size="small" variant="contained" color="primary" {...props}>
        Buy Track ${TRACK_PURCHASE_PRICE}
      </Button>
    </Link>
  );
};

export const DownloadTrackButton = (props: ButtonProps & { track: Track }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        {...props}
        onClick={() => setOpen(true)}
      >
        Download
      </Button>
      <DownloadTrackDialog
        track={props.track}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

import React, { ChangeEvent, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { PackCodeBundle, UpsertPackCodeBody } from '../../../../api/packCodes';
import { useUpsertPackCodeApiCall } from '../../../../api/packCodes/hooks';

interface NewPackCodesProps {
  handleModalClose: VoidFunction;
  onCreatePlan: VoidFunction;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: { flexWrap: 'wrap' },
    input: {
      margin: theme.spacing(3, 0),
      width: '15vw',
    },
    textInput: {
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  })
);

export default function NewPackCodesForm({
  handleModalClose,
  onCreatePlan,
}: NewPackCodesProps) {
  const classes = useStyles();
  const createResult = useUpsertPackCodeApiCall();
  const [bundleChange, setBundleChange] = useState<PackCodeBundle>();
  const [packCodeName, setPackCodeName] = useState<string>('');
  const [errors, setErrors] = useState({
    packCodeNameError: '',
    bundleError: '',
  });

  const handlePackCodeName = (event: ChangeEvent<HTMLInputElement>) => {
    setPackCodeName(event.target.value);
  };

  const handleBundleChange = (event: any) => {
    setBundleChange(event.target.value);
  };

  const handleUpsertPackCode = (upsertPackCodeBody: UpsertPackCodeBody) => {
    const isValid = validate(
      upsertPackCodeBody.code,
      upsertPackCodeBody.bundle
    );
    if (isValid) {
      createResult
        .runApiCall(upsertPackCodeBody)
        .then(handleModalClose)
        .then(() => onCreatePlan())
        .catch((error) => console.error(error));
    }
  };

  const validate = (packCodeName: string, bundle?: string) => {
    const charMin = 1;
    const charMax = 50;
    let packCodeNameError = '';
    let bundleError = '';

    if (packCodeName.length < charMin || packCodeName.length > charMax) {
      packCodeNameError = 'Pack code name must be between 1 and 50 characters';
    }
    if (bundle == undefined) {
      bundleError = 'A bundle must be selected';
    }

    if (packCodeNameError || bundleError) {
      setErrors({
        packCodeNameError,
        bundleError,
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <DialogTitle>Create New Pack Code</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" alignItems="center" justify="center">
          <FormControl
            className={classes.input}
            error={errors.packCodeNameError ? true : false}
          >
            <InputLabel className={classes.textInput}>Pack Code</InputLabel>

            <OutlinedInput
              error={errors.packCodeNameError ? true : false}
              autoFocus
              required={true}
              id="coupon"
              type="string"
              value={packCodeName}
              onChange={handlePackCodeName}
            />
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.packCodeNameError}
          </Typography>

          <FormControl
            variant="outlined"
            className={classes.input}
            error={errors.bundleError ? true : false}
          >
            <InputLabel>Bundle</InputLabel>
            <Select
              id="interval"
              error={errors.bundleError ? true : false}
              value={bundleChange}
              onChange={handleBundleChange}
              label={'Interval'}
            >
              <MenuItem value={PackCodeBundle.HIGH_END}>
                High End Music Collection
              </MenuItem>
              <MenuItem value={PackCodeBundle.VIDEOGRAPHER}>
                Videographer Collection
              </MenuItem>
              <MenuItem value={PackCodeBundle.STOCK_MUSIC}>
                Stock Music Pro Bundle Collection
              </MenuItem>
            </Select>
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.bundleError}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleUpsertPackCode({
                code: packCodeName,
                bundle: bundleChange,
              })
            }
          >
            Submit
          </Button>
          <Typography color="error">{createResult.error}</Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

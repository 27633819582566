export const LIBRARY_SUBSCRIPTION_PRICE = 149; // Dollars / year
export const TRACK_PURCHASE_PRICE = 99; // Dollars / year
export const MUSIC_PACK_PRICE = 199; // Dollars / year
export const CONTACT_US_EMAIL = 'info@pleasantpictures.club';
export const INSTAGRAM_LINK =
  'https://www.instagram.com/pleasantpicturesmusicclub/';
export const FACEBOOK_LINK =
  'https://www.facebook.com/pleasantpicturesmusicclub/';
export const STRIPE_PUBLIC_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_Vv0KBqWJwcfGfKWYNXUb1qCD'
    : 'pk_test_HgX0WOHEY5xZXY2ydUnGbEhD';
export const GOOGLE_ANALYTICS_ID = 'UA-111496115-1';

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import WithEdgePaddingLayout from '../../layouts/WithEdgePaddingLayout';
import { HEADER_HEIGHT } from '../Header/Header';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import { Typography, Link as MUILink, IconButton } from '@material-ui/core';
import {
  CONTACT_US_EMAIL,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
} from '../../constants/values';
import { Facebook, Instagram } from '@material-ui/icons';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      zIndex: 1,
      borderTop: `1px solid ${theme.palette.borders.dark}`,
      background: theme.palette.background.default,
      padding: theme.spacing(3, 2),
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      justifyContent: 'space-between',
      '& p': {
        fontSize: 12,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '& > div': {
        flex: 1,
      },
    },
    left: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(0, 2),
      },
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      },
    },
    center: {},
    right: {
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& > *': {
        marginRight: theme.spacing(1),
        fontSize: 12,
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
        justifyContent: 'flex-start',
        fontSize: 8,
      },
    },
  })
);

export const FOOTER_HEIGHT = 150 - HEADER_HEIGHT;

const Footer = () => {
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <WithEdgePaddingLayout className={classes.container}>
      <div className={classes.left}>
        <Link to={AppRoutes.TERMS}>
          <Typography color="textSecondary">Terms</Typography>
        </Link>
        <Link to={AppRoutes.SUBSCRIPTION_TERMS}>
          <Typography color="textSecondary">
            Music Subscription License Agreement
          </Typography>
        </Link>
        <Link to={AppRoutes.POLICY}>
          <Typography color="textSecondary">Privacy Policy</Typography>
        </Link>
      </div>
      <div className={classes.right}>
        <Typography color="textSecondary">
          ©{year}, Pleasant Pictures Library LLC. All rights reserved.
        </Typography>
      </div>
    </WithEdgePaddingLayout>
  );
};

export default Footer;

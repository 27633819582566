import React from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { useIsMobile } from '../../hooks/isMobile';
import { SearchSection, useSearch } from '../../providers/SearchProvider';
import { TagCategory } from '../../api/tags';
import clsx from 'clsx';

export const HEADER_HEIGHT = 70;
export const MOBILE_HEADER_HEIGHT = 70;

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    button: {},
    active: {},
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
      '& > *': {
        margin: theme.spacing(0, 0.5),
      },
    },
    container: {
      display: 'inline-block',
      borderRadius: 8,
      background: theme.palette.common.white,
      padding: theme.spacing(1),
      maxWidth: '100%',
      overflowX: 'auto',
    },
  })
);

const SearchSectionPicker = () => {
  const classes = useStyles();
  const sections: SearchSection[] = [
    'Collections',
    ...Object.values(TagCategory),
  ];
  const { selectedSection, onSelectSection } = useSearch();

  const handleClick = (value: SearchSection) => () => {
    onSelectSection(value);
  };

  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer}>
        {sections.map((section) => (
          <div className={clsx(classes.button)} key={section}>
            <Button
              onClick={handleClick(section)}
              variant={selectedSection === section ? 'contained' : 'text'}
              color={selectedSection === section ? 'primary' : 'primary'}
              size={isMobile ? 'small' : undefined}
            >
              {section}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchSectionPicker;

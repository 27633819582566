import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Category } from '../../api/categories';
import {
  useGetCategoriesApiCall,
  useGetCategoryTracksApiCall,
} from '../../api/categories/hooks';
import { Tag, TAG_CATEGORIES } from '../../api/tags';
import { useGetTagsApiCall } from '../../api/tags/hooks';
import { parse } from 'query-string';
import { useGetTracksApiCall } from '../../api/tracks/hooks';
import { GetTracksBody } from '../../api/tracks';
import { useSearch } from '../../providers/SearchProvider';

export type ChildListItem = {
  name: string;
  active: boolean;
  item: Tag | Category;
};

export const useLibraryBrowserLogic = () => {
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [lastActiveCategory, setLastActiveCategory] = useState<Category | null>(
    null
  );
  const [selectedTagParent, setSelectedTagParent] = useState<string | null>(
    null
  );
  const categories = useGetCategoriesApiCall();
  const tags = useGetTagsApiCall();
  const [childListItems, setChildListItems] = useState<ChildListItem[]>([]);
  const qp = parse(history.location.search);
  const {
    tags: selectedTags,
    onUpdateTags: setSelectedTags,
    searchTextItems,
    selectedSection,
  } = useSearch();
  useEffect(() => {
    if (selectedSection === 'Collections') {
      setSelectedTagParent(null);
      setSelectedCategory(lastActiveCategory);
    } else {
      setSelectedCategory(null);
      setSelectedTagParent(selectedSection);
    }
  }, [selectedSection]);

  const getTrackVariables = (): GetTracksBody => {
    const variables: GetTracksBody = {};
    if (selectedTagParent || searchTextItems.length) {
      variables.tags = selectedTags.map((t) => `${t._id}`);
      variables.searchTextItems = searchTextItems.length
        ? searchTextItems
        : undefined;
    } else if (selectedCategory) {
      variables.categoryID = `${selectedCategory._id}`;
    }
    if (!selectedCategory && !selectedTags.length) {
      if (typeof qp.track === 'string') {
        variables.trackID = qp.track;
      }
    }

    return variables;
  };

  const tracks = useGetTracksApiCall(getTrackVariables(), {
    skip: !selectedTags.length && !searchTextItems.length && !qp.track,
  });
  const categoryTracks = useGetCategoryTracksApiCall(
    { _id: selectedCategory?._id || -1 },
    {
      skip: !selectedCategory,
    }
  );

  // Initially load up the first category in the list
  useEffect(() => {
    if (categories.data?.length) {
      // If there is a ?category=12 or some other id in the url, load that category
      if (typeof qp.category === 'string') {
        const id = parseInt(qp.category);
        const category = categories.data.find((c) => c._id === id);
        if (category) {
          setSelectedCategory(category);
          return;
        }
      } else if (typeof qp.track === 'string') {
        return;
      }
      setSelectedCategory(categories.data[0]);
    }
  }, [categories.data]);
  useEffect(() => {
    if (selectedCategory) {
      setLastActiveCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const handleSelectTagParent = (parent: string) => {
    setSelectedTagParent(parent);
  };

  useEffect(() => {
    if (selectedTagParent === null && categories.data) {
      setChildListItems(
        categories.data.map((c) => ({
          name: c.name,
          item: c,
          active: selectedCategory?._id === c._id,
        }))
      );
    } else if (selectedTagParent !== null && tags.data) {
      setChildListItems(
        tags.data
          .filter((t) => t.category === selectedTagParent)
          .map((t) => ({
            name: t.name,
            item: t,
            active: Boolean(selectedTags.find((tag) => tag._id === t._id)),
          }))
      );
    }
  }, [
    selectedTagParent,
    tags.data,
    categories.data,
    selectedTags,
    selectedCategory,
  ]);

  useEffect(() => {
    if (searchTextItems.length) {
      setSelectedTagParent('search');
      setSelectedCategory(null);
    }
    getTrackVariables();
  }, [searchTextItems]);

  const handleSelectTag = (tag: Tag) => {
    setSelectedCategory(null);
    const existsAlready = selectedTags.find((t) => t._id === tag._id);
    if (existsAlready) {
      setSelectedTags(selectedTags.filter((t) => t._id !== tag._id));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
    if (selectedTagParent) {
      handleSelectTagParent(selectedTagParent); // Re calling this so it will update the
    }
  };

  // For when they click "Collections" in the UI to view all of the categories
  const handleSelectCategoryParent = () => {
    setSelectedTagParent(null);
    setSelectedCategory(null);
  };

  const handleSelectCategory = (category: Category) => {
    setSelectedCategory(category);
    history.push({
      pathname: history.location.pathname,
      search: `?category=${category._id}&name=${category.name}`,
    });
  };

  const handleClearSelectedTags = () => {
    setSelectedTags([]);
  };

  return {
    onSelectTagParent: handleSelectTagParent,
    onSelectCategory: handleSelectCategory,
    onSelectTag: handleSelectTag,
    onSelectCategoryParent: handleSelectCategoryParent,
    childListItems,
    tagCategories: TAG_CATEGORIES,
    selectedCategory,
    selectedTags,
    selectedTagParent,
    loading: categories.loading || tags.loading,
    tracks: (selectedCategory ? categoryTracks.data : tracks.data) || [],
    tracksLoading: selectedCategory ? categoryTracks.loading : tracks.loading,
    categories: categories.data,
    onClearSelectedTags: handleClearSelectedTags,
    showUrlTrack:
      !selectedTags.length &&
      !searchTextItems.length &&
      !selectedCategory &&
      qp.track,
  };
};

import { Chip } from '@material-ui/core';
import React from 'react';
import { Tag } from '../../api/tags';

interface Props {
  tag: Tag;
  selectedTags: Tag[];
  onSelectTag: (tag: Tag) => void;
}
export const TagChip = ({ tag, selectedTags, onSelectTag }: Props) => {
  return (
    <Chip
      label={tag.name}
      key={tag._id}
      onClick={() => onSelectTag(tag)}
      onDelete={
        Boolean(selectedTags.find((t) => t._id === tag._id))
          ? () => onSelectTag(tag)
          : undefined
      }
      color={
        Boolean(selectedTags.find((t) => t._id === tag._id))
          ? undefined
          : 'primary'
      }
    />
  );
};

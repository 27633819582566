import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../../types';
import { useLoginFormLogic } from './useLoginFormLogic';
import { Button, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../constants/routes';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {},
    button: { marginRight: 'auto' },
    oauth: {
      display: 'flex',
      flexWrap: 'wrap',
      textAlign: 'left',
      '& > a': { width: '100%' },
      '& > a > img': {
        borderRadius: 8,
        marginBottom: theme.spacing(2),
        display: 'inline-block',
      },
    },
  })
);
interface LoginFormProps {
  hideOauthButtons?: boolean;
  onLogin: VoidFunction;
}

const LoginForm = ({ hideOauthButtons, onLogin }: LoginFormProps) => {
  const classes = useStyles();
  const {
    email,
    onChangeEmail,
    password,
    onChangePassword,
    onSubmit,
    apiResponse,
    hasSubmitted,
    validation,
  } = useLoginFormLogic({ onLogin });

  return (
    <div className={classes.container}>
      {!hideOauthButtons && (
        <div className={classes.oauth}>
          <a href={`${process.env.REACT_APP_API_URL}/auth/google`}>
            <img
              style={{ width: '50%' }}
              src="https://pleasantpictures-images.s3-us-west-2.amazonaws.com/google/btn_google_signin_light_normal_web.png"
            />
          </a>
          <a href={`${process.env.REACT_APP_API_URL}/auth/facebook`}>
            <img
              style={{ width: '50%' }}
              src="https://pleasantpictures-images.s3-us-west-2.amazonaws.com/facebook.png"
            />
          </a>
        </div>
      )}
      <form onSubmit={onSubmit} className={classes.container}>
        <TextField
          fullWidth
          value={email}
          label="Email"
          type="email"
          name="email"
          onChange={onChangeEmail}
          error={hasSubmitted && Boolean(validation.email)}
          helperText={hasSubmitted && validation.email}
        />
        <TextField
          fullWidth
          type="password"
          value={password}
          label="Password"
          name="password"
          onChange={onChangePassword}
          error={hasSubmitted && Boolean(validation.password)}
          helperText={hasSubmitted && validation.password}
        />
        <Button
          variant="contained"
          color="secondary"
          disabled={apiResponse.loading}
          onClick={onSubmit}
          className={classes.button}
          type="submit"
        >
          Login
        </Button>
        <Typography color="error">{apiResponse.error}</Typography>
      </form>
      <div>
        <Link to={AppRoutes.FORGOT_PASSWORD}>
          <Typography color="textSecondary" variant="overline">
            Forgot Password?
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;

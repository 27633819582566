import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export enum PlanLevel {
  SMALL_BUSINESS = 'small-business',
  PREMIUM = 'premium',
  SOCIAL = 'social',
}

export type Plan = {
  active: boolean;
  amount: number;
  billing_scheme: string;
  id: string;
  interval?: 'year' | 'month';
  interval_count: number;
  metadata: {
    level?: PlanLevel;
    autoRenew: 'true' | 'false';
  };
  nickname: string;
  product: string;
};

export type GetPlansResponse = Plan[];

export const getPlans = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetPlansResponse> => {
  const result = await fetcher<GetPlansResponse>(`/stripe/plans`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type CreatePlanBody = Pick<Plan, 'amount' | 'interval' | 'metadata'> & {
  name: string;
  // cost: string;
};

export type CreatePlanResponse = Plan;

export const createPlan = async (
  body: CreatePlanBody,
  options: FetcherRequestConfig
): Promise<CreatePlanResponse> => {
  const result = await fetcher<CreatePlanResponse>('/stripe/plans', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type DeletePlanBody = {
  id: string;
};

export type DeletePlanResponse = {};

export const deletePlan = async (
  { id }: DeletePlanBody,
  options: FetcherRequestConfig
): Promise<DeletePlanResponse> => {
  await fetcher<DeletePlanResponse>(`/stripe/plans/${id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return {};
};

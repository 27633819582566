import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../../types';
import { useSignupFormLogic } from './useSignupFormLogic';
import {
  Button,
  Checkbox,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckboxInput from '../../CheckboxInput/CheckboxInput';
import { CreateUserBody } from '../../../api/users';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: { width: '100%' },
    button: { marginRight: 'auto' },
  })
);
interface SignupFormProps {
  hideOauthButtons?: boolean;
  onSignup?: VoidFunction;
  onChange?: (newFormData: CreateUserBody) => void;
  showSubmitButton?: boolean;
}

const SignupForm = ({
  hideOauthButtons,
  onSignup,
  onChange,
  showSubmitButton = true,
}: SignupFormProps) => {
  const classes = useStyles();
  const {
    formData,
    handleChangeValue,
    onSubmit,
    apiResponse,
    hasSubmitted,
    validation,
  } = useSignupFormLogic({ onSignup, onChange });

  return (
    <form onSubmit={onSubmit} className={classes.container}>
      <TextField
        fullWidth
        label="Name"
        name="name"
        onChange={handleChangeValue}
        error={hasSubmitted && Boolean(validation.name)}
        helperText={hasSubmitted && validation.name}
      />
      <TextField
        fullWidth
        label="Email"
        type="email"
        name="email"
        onChange={handleChangeValue}
        error={hasSubmitted && Boolean(validation.email)}
        helperText={hasSubmitted && validation.email}
      />
      <TextField
        fullWidth
        label="Zip Code"
        type="zip"
        name="zip"
        onChange={handleChangeValue}
        error={hasSubmitted && Boolean(validation.zip)}
        helperText={hasSubmitted && validation.zip}
      />
      <TextField
        fullWidth
        type="password"
        label="Password"
        name="password"
        onChange={handleChangeValue}
        error={hasSubmitted && Boolean(validation.password)}
        helperText={hasSubmitted && validation.password}
      />
      <CheckboxInput
        checked={formData.notifications}
        onChange={handleChangeValue}
        label="I would like to receive newsletters from Pleasant Pictures Music Club."
        name="notifications"
      />
      <CheckboxInput
        checked={formData.terms}
        onChange={handleChangeValue}
        name="terms"
        label={
          <>
            By creating an account, I agree to Pleasant Pictures Music Club's{' '}
            <Link href="/terms" target="_blank">
              Terms Of Use{' '}
            </Link>
            and{' '}
            <Link target="_blank" href="/privacy">
              Privacy Policy
            </Link>
            .
          </>
        }
      />
      {showSubmitButton && (
        <Button
          color="secondary"
          variant="contained"
          disabled={apiResponse.loading || !formData.terms}
          onClick={onSubmit}
          className={classes.button}
          type="submit"
        >
          Signup
        </Button>
      )}
      <Typography color="error">{apiResponse.error}</Typography>
    </form>
  );
};

export default SignupForm;

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { IconButton, Typography } from '@material-ui/core';
import { Tag } from '../../api/tags';
import { TagChip } from './TagChip';
import { Delete } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
      fontWeight: 'lighter',
      marginLeft: theme.spacing(1),
    },
    container: {},
    selected: {
      marginBottom: theme.spacing(2),
    },
  })
);

interface Props {
  onSelectTag: (tag: Tag) => void;
  tags: Tag[];
  selectedTags: Tag[];
  title: string;
  onClearSelected: VoidFunction;
}

export const TagPicker = ({
  tags,
  onSelectTag,
  selectedTags,
  title,
  onClearSelected,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {selectedTags.length > 0 && (
        <div className={classes.selected}>
          <IconButton onClick={onClearSelected}>
            <Close />
          </IconButton>
          {selectedTags.map((tag) => (
            <TagChip
              tag={tag}
              selectedTags={selectedTags}
              onSelectTag={onSelectTag}
            />
          ))}
        </div>
      )}
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      {tags.map((tag) => (
        <TagChip
          tag={tag}
          selectedTags={selectedTags}
          onSelectTag={onSelectTag}
        />
      ))}
    </div>
  );
};

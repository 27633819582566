import { Session } from '../types';
import { clearToken, getToken, persistToken } from '../utils/tokenUtils';
import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useGetMeApiCall } from '../api/users/hooks';
import { User } from '../api/users';
import { Track } from '../api/tracks';
import { Howl, Howler } from 'howler';
import { useCurrentRoute } from '../hooks/useCurrentRoute';
import { AppRoutes } from '../constants/routes';
import { useHistory, useLocation } from 'react-router';
import { AnalyticsService } from '../utils/analytics';

type AudioContextReturn = {
  playing: boolean;
  currentTrack: Track | null;
  currentPlaylist: Track[];
  onNext: VoidFunction;
  onPrevious: VoidFunction;
  onPlay: VoidFunction;
  onSeek: (progress: number) => void;
  onPause: VoidFunction;
  onSelectTrack: (track: Track) => void;
  setPlaylist: (tracks: Track[]) => void;
  duration: number;
  currentSound: Howl | null;
};

export const AudioContext = createContext<AudioContextReturn>({
  playing: false,
  currentTrack: null,
  currentPlaylist: [],
  onNext: () => {},
  onPrevious: () => {},
  onPlay: () => {},
  onPause: () => {},
  onSelectTrack: (track: Track) => {},
  onSeek: (_: number) => {},
  setPlaylist: () => {},
  duration: 0,
  currentSound: null,
});

export const useAudioState = (): AudioContextReturn => {
  const [currentSound, setCurrentSound] = useState<Howl | null>(null);
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);
  const [currentPlaylist, setCurrentPlaylist] = useState<Track[]>([]);
  const [playing, setPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    currentSound?.stop();
    currentSound?.unload();
    if (currentTrack) {
      setCurrentSound(
        new Howl({
          html5: true,
          src: currentTrack.Media.find((m) => m.isFullMedia)?.link || '',
          autoplay: true,
          onplay: () => setPlaying(true),
          onpause: () => setPlaying(false),
        })
      );
    }
  }, [currentTrack]);

  const handlePlay = () => {
    if (currentSound?.playing() === false) {
      currentSound.play();
    }
  };
  const handlePause = () => {
    if (currentSound?.playing()) {
      currentSound.pause();
    }
  };
  const handleSkip = (step: 1 | -1) => {
    if (!currentTrack && currentPlaylist.length) {
      setCurrentTrack(currentPlaylist[0]);
    } else if (currentTrack && currentPlaylist.length) {
      const index = currentPlaylist.findIndex(
        (t) => t._id === currentTrack._id
      );
      let newIndex = index + step;
      if (newIndex >= currentPlaylist.length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = currentPlaylist.length - 1;
      }

      setCurrentTrack(currentPlaylist[newIndex]);
    }
  };
  const handleSeek = (percentage: number) => {
    if (!currentSound) {
      return;
    }
    currentSound?.seek(duration * percentage);
  };
  const handleSelect = (track: Track) => {
    setCurrentTrack(track);
    AnalyticsService().trackPlay(track);
  };
  useEffect(() => {
    currentSound?.play();
  }, []);

  useEffect(() => {
    try {
      currentSound?.once('end', () => {
        handleSkip(1);
      });
      currentSound?.on('load', () => {
        setDuration(currentSound.duration() || 0);
      });
      return () => {
        currentSound?.off('load');
      };
    } catch (err) {
      console.error(err);
    }
  }, [currentSound]);

  return {
    playing,
    currentTrack,
    currentPlaylist,
    onNext: () => handleSkip(1),
    onPrevious: () => handleSkip(-1),
    onPlay: handlePlay,
    onPause: handlePause,
    onSelectTrack: handleSelect,
    onSeek: handleSeek,
    setPlaylist: setCurrentPlaylist,
    duration,
    currentSound,
  };
};

export const useAudio = () => {
  return useContext(AudioContext);
};

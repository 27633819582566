import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import WithEdgePaddingLayout from '../../layouts/WithEdgePaddingLayout';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../../hooks/isMobile';
import { AppRoutes } from '../../constants/routes';
import SearchSectionPicker from '../SearchSectionPicker/SearchSectionPicker';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      top: 0,
      left: 0,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(60vh - 71px)',
    },
    bgVideo: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: 'initial',
        height: 'calc(100vh - 71px)',
      },
    },
    info: {
      position: 'absolute',
      bottom: '10%',
      left: '2%',
      right: '2%',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        left: theme.spacing(2),
        right: theme.spacing(2),
      },
    },
    whiteButton: { background: theme.palette.common.white },
    transparentButton: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
    },
    grid: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    actionRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    infoText: {
      '& > h3:last-child': {
        fontSize: '2rem',
      },

      [theme.breakpoints.down('sm')]: {
        '& > h3': {
          fontSize: '18px',
        },
        '& > h3:last-child': {
          fontSize: '14px',
        },
      },
    },
  })
);

interface Props {}

const HeroImage = ({}: Props) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <div className={classes.container}>
      <video playsInline autoPlay muted loop className={classes.bgVideo}>
        <source
          src="https://pleasantpictures-images.s3.us-west-2.amazonaws.com/PPMC+Final+with+Color.mov"
          type="video/mp4"
        />
      </video>
      <div className={classes.info}>
        <div className={classes.infoText}>
          <Typography color="inherit" variant="h3">
            Stock music with a ton of personality and zero fluff.
          </Typography>
        </div>
        <div className={classes.actionRow}>
          {!isMobile && <SearchSectionPicker />}
          <Button
            size="large"
            variant="contained"
            color="secondary"
            href={AppRoutes.PLANS}
          >
            View Plans
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;

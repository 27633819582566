import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useGetPlansApiCall,
  useDeletePlanApiCall,
} from '../../../../api/plans/hooks';
import NewPlanForm from './NewPlanForm';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  Dialog,
} from '@material-ui/core';

import { Delete, Check } from '@material-ui/icons';
import { Plan, DeletePlanBody } from '../../../../api/plans';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
  })
);

export default function Plans() {
  const classes = useStyles();
  const result = useGetPlansApiCall();
  const deleteResult = useDeletePlanApiCall();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleDeletePlan = (deletePlanBody: DeletePlanBody) => {
    deleteResult.runApiCall(deletePlanBody).then(() => result.refetch());
  };

  const handleModalOpen = () => {
    setCreateModalOpen(true);
  };

  const handleModalClose = () => {
    setCreateModalOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Plans
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={result.loading}
        onClick={handleModalOpen}
      >
        Add New
      </Button>
      <Dialog
        open={createModalOpen}
        onClose={handleModalClose}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <NewPlanForm
          handleModalClose={handleModalClose}
          onCreatePlan={result.refetch}
        />
      </Dialog>
      <div className={classes.mainContent}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            {result.loading ? (
              <CircularProgress />
            ) : (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>
                      Creator Package
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Access Level
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Amount/Interval
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Auto Renewed
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data?.map((dataReceived: Plan, key) => (
                    <TableRow
                      key={dataReceived.id}
                      className={key % 2 ? classes.tableRow : classes.table}
                    >
                      <TableCell>{dataReceived.nickname}</TableCell>

                      <TableCell align="right">
                        {dataReceived.metadata.level}
                      </TableCell>
                      <TableCell align="right">
                        {'$' +
                          dataReceived.amount / 100 +
                          '/' +
                          dataReceived.interval}
                      </TableCell>

                      <TableCell align="right">
                        {dataReceived.metadata.autoRenew == 'true' ? (
                          <Check />
                        ) : null}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            handleDeletePlan({ id: dataReceived.id })
                          }
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

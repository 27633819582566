import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useCreateFavoriteTrackApiCall,
  useDeleteFavoriteTrackApiCall,
} from '../../api/favoriteTracks/hooks';
import { Track } from '../../api/tracks';
import { AppRoutes } from '../../constants/routes';
import { useFavoriteTracks } from '../../providers/FavoriteTracksProvider';
import { useSession } from '../../providers/SessionProvider';
import { AnalyticsService } from '../../utils/analytics';
import {
  FavoriteButton as FavoriteIcon,
  ShareButton as ShareIcon,
} from '../IconButtons/IconButtons';
import { ShareTrackModal } from './ShareTrackModal';

export interface FavoriteButtonProps {
  track?: Track | null;
}

export const FavoriteButton = ({ track }: FavoriteButtonProps) => {
  const { favoriteTracks, refetch } = useFavoriteTracks();
  const favoriteTrack = favoriteTracks.find((f) => f.Track._id === track?._id);
  const favoriteTrackId = favoriteTrack?._id;
  const [localFavorite, setLocalFavorite] = useState(Boolean());
  const setFavoriteApi = useCreateFavoriteTrackApiCall();
  const removeFavoriteApi = useDeleteFavoriteTrackApiCall();
  const session = useSession();
  const history = useHistory();

  useEffect(() => {
    setLocalFavorite(Boolean(favoriteTrackId));
  }, [favoriteTrackId]);

  const handleFavorite = async () => {
    if (!session.user) {
      history.push(AppRoutes.LOGIN);
      return;
    }

    setLocalFavorite(!localFavorite);
    if (localFavorite && favoriteTrackId && track) {
      await removeFavoriteApi.runApiCall({ _id: favoriteTrackId });
    } else if (track) {
      await setFavoriteApi.runApiCall({ trackID: track._id });
      AnalyticsService().trackFavorite(track);
    } else {
      return;
    }
    refetch();
  };

  return <FavoriteIcon filled={localFavorite} onClick={handleFavorite} />;
};

export const ShareButton = ({ track }: { track: Track }) => {
  const [shareOpen, setShareOpen] = useState(false);
  const handleShare = () => {
    setShareOpen(true);
  };
  return (
    <>
      <ShareIcon onClick={handleShare} />
      <ShareTrackModal
        track={track}
        open={shareOpen}
        onClose={() => setShareOpen(false)}
      />
    </>
  );
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const useCurrentRoute = () => {
  const history = useHistory();
  const [currentRoute, setCurrentRoute] = useState<string>(
    history.location.pathname
  );
  useEffect(() => {
    return history.listen((location) => {
      setCurrentRoute(location.pathname);
    });
  }, []);
  return { currentRoute };
};

import { Session } from '../types';
import { clearToken, getToken, persistToken } from '../utils/tokenUtils';
import { createContext, useState, useContext, useEffect } from 'react';
import { useGetMeApiCall } from '../api/users/hooks';
import { User } from '../api/users';
import { AnalyticsService } from '../utils/analytics';
import { GetPurchasedTracksResponse, Track } from '../api/tracks';
import { useGetPurchasedTracksApiCall } from '../api/tracks/hooks';
import { useSession } from './SessionProvider';

type PurchasedTracksContextReturn = {
  purchasedTracks: Track[];
  loading: boolean;
  refetch: () => Promise<GetPurchasedTracksResponse | null>;
};

export const PurchasedTracksContext = createContext<PurchasedTracksContextReturn>(
  {
    purchasedTracks: [] as Track[],
    loading: true,
    refetch: async () => {
      return null;
    },
  }
);

export const usePurchasedTracksState = ({ user }: { user: User | null }) => {
  const [purchasedTracks, setPurchasedTracks] = useState<Track[]>([]);

  const getPurchasedTracksResponse = useGetPurchasedTracksApiCall({
    skip: Boolean(user) === false,
  });

  useEffect(() => {
    if (getPurchasedTracksResponse.data) {
      setPurchasedTracks(
        getPurchasedTracksResponse.data?.map(
          (purchasedTrack) => purchasedTrack.Track
        ) || ([] as Track[])
      );
    }
  }, [getPurchasedTracksResponse.data]);

  useEffect(() => {
    if (user) {
      getPurchasedTracksResponse.refetch();
    } else {
      setPurchasedTracks([]);
    }
  }, [user]);

  return {
    loading: getPurchasedTracksResponse.loading,
    purchasedTracks,
    refetch: getPurchasedTracksResponse.refetch,
  };
};

export const usePurchasedTracks = () => {
  return useContext(PurchasedTracksContext);
};

import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export type Tag = {
  _id: number;
  createdAt: string;
  name: string;
  category: string;
  info?: {};
  active: boolean;
};

export enum TagCategory {
  VIDEO_THEME = 'Video Theme',
  Genre = 'Genre',
  Mood = 'Mood',
  Instruments = 'Instruments',
  Attributes = 'Attributes',
  Tempo = 'Tempo',
}

export const TAG_CATEGORIES = Object.values(TagCategory);

//For simplifying tab selection
export const META_TAG_CATEGORIES = ['All', ...TAG_CATEGORIES, 'No Category'];
export type GetTagsResponse = Tag[];

export const getTags = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetTagsResponse> => {
  const result = await fetcher<GetTagsResponse>(`/tags`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export type UpdateTagBody = {
  _id: number;
} & Partial<Tag>;

export type UpdateTagResponse = Tag;

export const updateTag = async (
  { _id, ...body }: UpdateTagBody,
  options: FetcherRequestConfig
): Promise<UpdateTagResponse> => {
  const result = await fetcher<UpdateTagResponse>(`/tags/${_id}`, {
    ...options,
    method: HTTPMethod.PUT,
    body,
  });
  const response = await result.json();
  return response;
};

export type CreateTagBody = Omit<Tag, 'createdAt' | '_id' | 'Media'>;

export type CreateTagResponse = Tag;

export const createTag = async (
  body: CreateTagBody,
  options: FetcherRequestConfig
): Promise<CreateTagResponse> => {
  const result = await fetcher<CreateTagResponse>('/tags', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type DeleteTagBody = {
  _id: number;
};

export type DeleteTagResponse = null;

export const deleteTag = async (
  { _id }: DeleteTagBody,
  options: FetcherRequestConfig
): Promise<DeleteTagResponse> => {
  await fetcher<DeleteTagResponse>(`/tags/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

import React, { useEffect, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTheme } from '../../types';
import { Link, useHistory } from 'react-router-dom';
import { login } from '../../api/auth';
import { AppRoutes } from '../../constants/routes';
import { useSession } from '../../providers/SessionProvider';
import { UserRole } from '../../api/users';
import { SearchBar } from '../SearchBar/SearchBar';
import { Search } from '@material-ui/icons';
import { useIsMobile } from '../../hooks/isMobile';
import { useSearch } from '../../providers/SearchProvider';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';

export const HEADER_HEIGHT = 70;
export const MOBILE_HEADER_HEIGHT = 70;

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    spacer: { flex: 1 },
    menuIconContainer: { height: 18, width: 18 },
    menuIcon: {
      color: theme.palette.text.secondary,
      borderTop: `${theme.palette.text.secondary} 1px solid`,
      borderBottom: `${theme.palette.text.secondary} 1px solid`,
      height: 6,
      width: 16,
    },
    closeMenuIcon: {
      color: theme.palette.text.secondary,
      display: 'block',
      margin: theme.spacing(1),
      marginLeft: 'auto',
    },
    hideMobileSection: {
      width: '100%',
      display: 'flex',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    buttonSpacing: {
      marginLeft: theme.spacing(3),
    },
    search: {
      display: 'flex',
      color: theme.palette.text.secondary,
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: theme.spacing(1),
        fontSize: 18,
      },
      cursor: 'pointer',
    },
    container: {
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(2),
      textAlign: 'center',
      display: 'grid',
      gridTemplateColumns: '1fr max-content 1fr',
      '& > :first-child': {
        textAlign: 'left',
      },
      '& > :last-child': {
        textAlign: 'right',
      },
    },
    menu: {
      padding: theme.spacing(2),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.common.black}`,
      zIndex: theme.zIndex.modal + 1,
      borderLeft: 'none',
      [theme.breakpoints.only('xs')]: {
        left: 0,
      },
    },
    menuItem: {
      padding: theme.spacing(1, 2),
      color: theme.palette.text.primary,
      '&:hover': {
        background: theme.palette.background.contrast,
      },
    },
    title: { cursor: 'pointer' },
  })
);

const LOGO_URL =
  'https://s3-us-west-2.amazonaws.com/pleasantpictures-images/Logo.gif';

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const search = useSearch();
  const session = useSession();
  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = (event?: React.MouseEvent<HTMLElement | Document>) => {
    event?.stopPropagation();
    event?.preventDefault();
    setOpen(false);
  };
  const urlMap = [
    {
      url: AppRoutes.MY_LIBRARY,
      name: 'My Library',
      hide: !session.user,
    },
    {
      url: AppRoutes.ADMIN,
      name: 'Admin',
      hide:
        Boolean(session?.user) === false ||
        session?.user?.role !== UserRole.ADMIN,
    },
    { url: `${AppRoutes.LANDING}?browse`, name: 'Browse Music' },
    { url: 'plans', name: 'Buy Now' },
    { url: 'about', name: 'About' },
    { url: 'https://ppmcshowcase.club/', name: 'PPMC Showcase' },
    { url: AppRoutes.HIGH_END_PACK, name: 'High End Music Pack' },
    { url: AppRoutes.STOCK_MUSIC_PACK, name: 'Stock Music Pro Bundle' },
    { url: AppRoutes.BACKGROUND_PACK, name: 'Background Pack' },
    { url: '/custom', name: 'Custom Music' },
    { url: '/faq', name: 'FAQ' },
    {
      url: '/settings',
      name: 'Settings',
      hide: Boolean(session.user) === false,
    },
    {
      url: AppRoutes.LOGOUT,
      name: 'Logout',
      hide: Boolean(session.user) === false,
    },
    {
      url: AppRoutes.LOGIN,
      name: 'Login',
      hide: Boolean(session.user) === true,
    },
  ];
  const isMobile = useIsMobile();
  const { currentRoute } = useCurrentRoute();
  const history = useHistory();
  const handleOpenSearch = () => {
    search.onOpenFullScreenSearch();
    history.push({
      pathname: AppRoutes.LANDING,
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <div className={classes.search} onClick={handleOpenSearch}>
            <SearchIcon />{' '}
            <Typography variant="body1" color="inherit">
              Search
            </Typography>
          </div>
        </div>
        <div
          onClick={() => history.push(AppRoutes.LANDING)}
          className={classes.title}
        >
          <Typography variant="body2">
            {isMobile ? 'PPMC' : 'PLEASANT PICTURES MUSIC CLUB'}
          </Typography>
          <Typography variant="body2" style={{ fontSize: 11 }}>
            {isMobile ? '' : 'Stock Music Library and Custom Music House'}
          </Typography>
        </div>
        <div>
          <IconButton
            onClick={handleClick}
            className={classes.menuIconContainer}
          >
            <div className={classes.menuIcon} />
          </IconButton>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.menu}>
            <IconButton onClick={handleClick} className={classes.closeMenuIcon}>
              <CloseIcon color="inherit" />
            </IconButton>
            {urlMap.map((item) =>
              // This can be cleaned up so there aren't 2 duplicates of child content
              item?.hide ? null : item?.url.indexOf('https') >= 0 ? (
                <a key={item.url} href={item.url}>
                  <div
                    onClick={() => handleClose()}
                    className={classes.menuItem}
                  >
                    <Typography color="inherit" variant="body2">
                      {item.name}
                    </Typography>
                  </div>
                </a>
              ) : (
                <Link key={item.url} to={item.url} href={item.url}>
                  <div
                    onClick={() => handleClose()}
                    className={classes.menuItem}
                  >
                    <Typography color="inherit" variant="body2">
                      {item.name}
                    </Typography>
                  </div>
                </Link>
              )
            )}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Header;

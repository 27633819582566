'use strict';

import { PackCodeBundle } from '../api/packCodes';
import { Track } from '../api/tracks';
import { GOOGLE_ANALYTICS_ID } from '../constants/values';
declare const gtag: any;

enum AnalyticsCategory {
  TRACK = 'track',
  USER = 'user',
  PACK = 'background_pack',
  SUBSCRIPTION = 'subscription',
}

export const AnalyticsService = () => {
  'ngInject';
  const Analytics = {
    trackPlay(track: Track) {
      Analytics.sendEvent(AnalyticsCategory.TRACK, 'play', track.name);
    },

    trackDownload(track: Track) {
      Analytics.sendEvent(AnalyticsCategory.TRACK, 'download', track.name);
    },

    trackFavorite(track: Track) {
      Analytics.sendEvent(AnalyticsCategory.TRACK, 'favorite', track.name);
    },

    trackPurchased(track: Track) {
      Analytics.sendEvent(AnalyticsCategory.TRACK, 'purchase', track.name);
    },

    packPurchase(pack: string) {
      Analytics.sendEvent(AnalyticsCategory.PACK, 'pack_purchased', pack);
    },
    packCodeRedemption(pack: string) {
      Analytics.sendEvent(AnalyticsCategory.PACK, 'pack_redeemed', pack);
    },
    subscriptionCreated(value?: number) {
      if ((window as any).fbq) {
        (window as any).fbq('track', 'Purchase', {
          currency: 'USD',
          value: value || 1,
        });
      }
      Analytics.sendEvent(
        AnalyticsCategory.SUBSCRIPTION,
        'subscription_created'
      );
    },

    sendEvent(
      category: AnalyticsCategory,
      action: string,
      label?: string | undefined,
      value?: number | string | undefined
    ) {
      if (process.env.NODE_ENV !== 'production') {
        return;
      }
      const body: {
        event_label?: string;
        value?: string | number;
        event_category: AnalyticsCategory;
      } = {
        event_category: category,
      };
      if (label) body['event_label'] = label;
      if (value) body['value'] = value;
      gtag('event', action, body);
    },

    pageView() {
      gtag('config', GOOGLE_ANALYTICS_ID, {
        page_path: window.location.href,
      });
      if ((window as any).fbq) {
        (window as any).fbq('track', window.location.href);
      }
    },

    setUserID(userID: number) {
      gtag('set', { user_id: userID });
    },
  };
  return Analytics;
};

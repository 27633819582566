import { createTag, deleteTag, getTags, updateTag } from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetTagsApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({ apiCall: getTags, variables: {}, ...options });
  return result;
};

export const useCreateTagApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createTag,
    ...options,
  });
  return result;
};

export const useUpdateTagApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: updateTag, ...options });
  return result;
};

export const useDeleteTagApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteTag, ...options });
  return result;
};

import {
  addTagToTrack,
  createTrack,
  CreateTrackBody,
  deleteTrack,
  getPurchasedTracks,
  getTrack,
  GetTrackBody,
  getTracks,
  GetTracksBody,
  removeTagFromTrack,
  updateTrack,
} from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetTracksApiCall = (
  variables?: GetTracksBody,
  options?: UseApiOptions
) => {
  const result = useApiCall({ apiCall: getTracks, variables, ...options });
  return result;
};

export const useGetPurchasedTracksApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({
    apiCall: getPurchasedTracks,
    variables: {},
    ...options,
  });
  return result;
};

export const useGetTrackApiCall = (
  variables: GetTrackBody,
  options?: UseApiOptions
) => {
  const result = useApiCall({ apiCall: getTrack, variables, ...options });
  return result;
};

export const useCreateTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createTrack,
    ...options,
  });
  return result;
};

export const useAddTagToTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: addTagToTrack,
    ...options,
  });
  return result;
};

export const useUpdateTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: updateTrack, ...options });
  return result;
};

export const useDeleteTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteTrack, ...options });
  return result;
};

export const useRemoveTagFromTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: removeTagFromTrack, ...options });
  return result;
};

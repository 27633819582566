import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import { useSession } from '../../../../providers/SessionProvider';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      textAlign: 'left',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    mainContent: { background: theme.palette.background.default },
    button: {
      display: 'flex',
      margin: theme.spacing(1),
      marginLeft: theme.spacing(4),
      maxWidth: theme.spacing(30),
      maxHeight: theme.spacing(6),
    },
    table: {},
    tableHeader: { fontWeight: 'bold' },
    tableRow: { background: theme.palette.background.default },
  })
);

export default function Reports() {
  const classes = useStyles();
  const session = useSession();

  return (
    <div>
      <Typography variant="h4" className={classes.title}>
        Reports
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        href={`${process.env.REACT_APP_API_URL}/api/admin/userReport?access_token=${session.token}`}
        download={true}
        target="_blank"
      >
        Download Users CSV
      </Button>
    </div>
  );
}

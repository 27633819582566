const download = (url: string, filename: string) => {
  const downloadElement = document.createElement('a');
  downloadElement.setAttribute('href', url);
  downloadElement.setAttribute('download', filename);
  downloadElement.style.display = 'none';
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
};
export default download;

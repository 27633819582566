import { createContext, useState, useContext, useEffect } from 'react';
import { User } from '../api/users';
import {
  FavoriteTrack,
  GetFavoriteTracksResponse,
} from '../api/favoriteTracks';
import { useGetFavoriteTracksApiCall } from '../api/favoriteTracks/hooks';

type FavoriteTracksContextReturn = {
  favoriteTracks: FavoriteTrack[];
  loading: boolean;
  refetch: () => Promise<GetFavoriteTracksResponse | null>;
};

export const FavoriteTracksContext = createContext<FavoriteTracksContextReturn>(
  {
    favoriteTracks: [] as FavoriteTrack[],
    loading: true,
    refetch: async () => {
      return null;
    },
  }
);

export const useFavoriteTracksState = ({ user }: { user: User | null }) => {
  const [favoriteTracks, setFavoriteTracks] = useState<FavoriteTrack[]>([]);

  const getFavoriteTracksResponse = useGetFavoriteTracksApiCall(
    {},
    {
      skip: Boolean(user) === false,
    }
  );

  useEffect(() => {
    if (getFavoriteTracksResponse.data) {
      setFavoriteTracks(
        getFavoriteTracksResponse.data
          ? getFavoriteTracksResponse.data
          : ([] as FavoriteTrack[])
      );
    }
  }, [getFavoriteTracksResponse.data]);

  useEffect(() => {
    if (user) {
      getFavoriteTracksResponse.refetch();
    } else {
      setFavoriteTracks([]);
    }
  }, [user]);

  return {
    loading: getFavoriteTracksResponse.loading,
    favoriteTracks,
    refetch: getFavoriteTracksResponse.refetch,
  };
};

export const useFavoriteTracks = () => {
  return useContext(FavoriteTracksContext);
};

import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React, { memo, useState } from 'react';
import { Track } from '../../api/tracks';
import { useIsMobile } from '../../hooks/isMobile';
import { useAudio } from '../../providers/AudioProvider';
import { CustomTheme } from '../../types';
import TrackListItem from '../TrackListItem/TrackListItem';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      borderBottom: `10px solid ${theme.palette.common.black}`,
      width: '100%',
    },
    table: {},
  })
);

export interface TrackListProps {
  tracks: Track[];
}

export const TrackList = memo(({ tracks }: TrackListProps) => {
  const classes = useStyles({});
  const audio = useAudio();
  const [selectedTrack, setSelectedTrack] = useState<Track | null>(null);
  const handleSelectTrack = (track: Track) => () => {
    setSelectedTrack(track);
    if (audio.currentPlaylist !== tracks) {
      audio.setPlaylist(tracks);
    }
  };

  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table className={classes.table}>
          <colgroup>
            {!isMobile && <col width={'10%'} />}
            <col />
            {!isMobile && (
              <>
                <col width={'10%'} />
                <col width={'10%'} />
                <col width={'10%'} />
                <col width={'20%'} />
              </>
            )}
          </colgroup>
          {!isMobile && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color={'textSecondary'}>#</Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'textSecondary'}>Title</Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'textSecondary'}>BPM</Typography>
                </TableCell>
                {/* <TableCell><Typography color={'textSecondary'}>Time</Typography></TableCell> */}
                {/* <TableCell></TableCell> */}
                <TableCell>
                  <Typography color={'textSecondary'}>Like</Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'textSecondary'}>Share</Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'textSecondary'}></Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}

          {tracks.map((track, index) => (
            <TrackListItem
              track={track}
              key={track._id}
              index={index}
              onClick={handleSelectTrack(track)}
              expanded={selectedTrack?._id === track._id}
            />
          ))}
        </Table>
      </TableContainer>
    </div>
  );
});

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { Track } from '../../api/tracks';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { useTrackListItemLogic } from './useTrackListItemLogic';
import { PauseButton, PlayButton } from '../IconButtons/IconButtons';
import {
  FavoriteButton,
  ShareButton,
} from '../ShareFavoriteButtons/ShareFavoriteButtons';
import {
  DownloadTrackButton,
  PurchaseTrackButton,
} from '../PurchaseButtons/PurchaseButtons';
import { useIsMobile } from '../../hooks/isMobile';

const useStyles = makeStyles<CustomTheme, { isActive: boolean }>(
  (theme: CustomTheme) =>
    createStyles({
      container: ({ isActive }) => ({
        padding: theme.spacing(2),
        cursor: 'pointer',
        background: isActive ? '#484143' : undefined,
        '& > *:first-child': {
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '& > *:last-child': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
        [theme.breakpoints.down('sm')]: {
          '& > *:first-child': {
            paddingLeft: 0,
            paddingRight: theme.spacing(0.5),
          },
          '& > *:last-child': {
            paddingRight: 0,
          },
        },
      }),
      description: { marginTop: theme.spacing(1) },
      favoriteButton: {
        paddingRight: theme.spacing(0.5),
      },
      shareButton: {
        paddingRight: theme.spacing(0.5),
      },
    })
);

interface Props {
  track: Track;
  index: number;
  expanded?: boolean;
  onClick?: VoidFunction;
}

const TrackListItem = (props: Props) => {
  const { track, expanded, index, onClick } = props;
  const {
    onPlay,
    onPause,
    playing,
    showDownloadButton,
    prettyIndex,
    bpm,
    description,
  } = useTrackListItemLogic({ track, index });

  const handleClick = () => {
    if (!expanded) {
      onPlay();
    }
    onClick?.();
  };

  const classes = useStyles({ isActive: Boolean(expanded) });
  const textColor = expanded ? 'textPrimary' : 'textSecondary';
  const isMobile = useIsMobile();
  return (
    <TableRow className={classes.container} onClick={handleClick}>
      {!isMobile && (
        <TableCell>
          {expanded ? (
            playing ? (
              <PauseButton onClick={onPause} />
            ) : (
              <PlayButton onClick={onPlay} />
            )
          ) : (
            <Typography color={textColor}>{prettyIndex}</Typography>
          )}
        </TableCell>
      )}
      <TableCell>
        <div>
          <Typography color={textColor}>{track.name}</Typography>
        </div>
        {Boolean(expanded && !isMobile) && (
          <div className={classes.description}>
            <Typography color={'textSecondary'}>{description}</Typography>
          </div>
        )}
      </TableCell>
      {!isMobile && (
        <TableCell>
          <Typography color={textColor}>{bpm}</Typography>
        </TableCell>
      )}
      {/* <TableCell>{prettyTime(trackDuration)}</TableCell> */}
      {/* <TableCell>{trackWaveForm}</TableCell> */}
      {!isMobile && (
        <>
          <TableCell className={classes.favoriteButton}>
            <FavoriteButton track={track} />
          </TableCell>
          <TableCell className={classes.shareButton}>
            <ShareButton track={track} />
          </TableCell>
        </>
      )}
      <TableCell>
        {showDownloadButton ? (
          <DownloadTrackButton track={track} />
        ) : (
          <PurchaseTrackButton track={track} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TrackListItem;

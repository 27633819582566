import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useCreateCouponApiCall } from '../../../../api/coupons/hooks';
import { CouponDuration, CreateCouponBody } from '../../../../api/coupons';

interface Props {
  handleModalClose: VoidFunction;
  onCreateCoupon: VoidFunction;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: { flexWrap: 'wrap' },
    input: {
      margin: theme.spacing(3, 0),
      width: '15vw',
    },
    textInput: {
      marginLeft: theme.spacing(1),
    },
    errors: {
      marginTop: theme.spacing(-2),
      marginBottom: theme.spacing(2),
    },
  })
);

export default function NewCouponForm(props: Props) {
  const classes = useStyles();
  const createResult = useCreateCouponApiCall();
  const [selectedAmountPercentRadio, setSelectedAmountPercentRadio] = useState(
    'amount'
  );
  const [durationChange, setDurationChange] = useState<CouponDuration>(
    CouponDuration.ONCE
  );
  const [percentOffChange, setPercentOffChange] = useState<number>();
  const [amountOffChange, setAmountOffChange] = useState<number>();
  const [couponName, setCouponName] = useState<string>('');
  const [errors, setErrors] = useState({
    couponNameError: '',
    percentAndAmountError: '',
    amountOffError: '',
    percentOffError: '',
  });

  const handleAmountPercentRadio = (event: any) => {
    setSelectedAmountPercentRadio(event.target.value);
    selectedAmountPercentRadio == 'amount'
      ? setAmountOffChange(undefined)
      : setPercentOffChange(undefined);
  };
  const handleDurationChange = (event: any) => {
    setDurationChange(event.target.value);
  };
  const handleAmountOffChange = (event: any) => {
    setAmountOffChange(event.target.value);
  };
  const handlePercentOffChange = (event: any) => {
    setPercentOffChange(event.target.value);
  };
  const handleCouponName = (event: any) => {
    setCouponName(event.target.value);
  };
  const handleCreateCoupon = (createCouponBody: CreateCouponBody) => {
    const isValid = validate(
      createCouponBody.id,
      createCouponBody.amount_off,
      createCouponBody.percent_off
    );
    if (isValid) {
      createResult
        .runApiCall(createCouponBody)
        .then(() => props.onCreateCoupon())
        .then(props.handleModalClose)
        .catch((error) => console.error(error));
    }
  };

  const validate = (
    couponName: string,
    amountOffChange?: number,
    percentOffChange?: number
  ) => {
    const charMin = 1;
    const charMax = 25;
    let couponNameError = '';
    let percentAndAmountError = '';
    let amountOffError = '';
    let percentOffError = '';

    if (couponName.length < charMin || couponName.length >= charMax) {
      couponNameError = 'Coupon name must be between 1 and 25 characters';
    }
    if (amountOffChange == undefined && percentOffChange == undefined) {
      percentAndAmountError =
        'Must specify either percent or amount off of coupon';
    }
    if (
      percentOffChange != undefined &&
      (percentOffChange > 100 || percentOffChange <= 0)
    ) {
      percentOffError = 'Percent off of coupon must be between 1-100%';
    }
    if (amountOffChange != undefined && amountOffChange <= 0.01) {
      amountOffError = 'Amount off of coupon must be greater than $0.01';
    }

    if (
      couponNameError ||
      percentAndAmountError ||
      percentOffError ||
      amountOffError
    ) {
      setErrors({
        couponNameError,
        percentAndAmountError,
        amountOffError,
        percentOffError,
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <DialogTitle>Add a New Coupon</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" alignItems="center" justify="center">
          <FormControl
            className={classes.input}
            error={errors.couponNameError ? true : false}
          >
            <InputLabel className={classes.textInput}>
              Coupon Name (ID)
            </InputLabel>
            <OutlinedInput
              error={errors.couponNameError ? true : false}
              autoFocus
              required={true}
              id="coupon"
              type="string"
              value={couponName}
              onChange={handleCouponName}
            />
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.couponNameError}
          </Typography>
          <FormControl error={errors.percentAndAmountError ? true : false}>
            <RadioGroup row>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedAmountPercentRadio === 'amount'}
                    value="amount"
                    onChange={handleAmountPercentRadio}
                    name="amount"
                  />
                }
                label="Amount Off"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedAmountPercentRadio === 'percent'}
                    value="percent"
                    onChange={handleAmountPercentRadio}
                    name="percent"
                  />
                }
                label="Percent Off"
                labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.percentAndAmountError}
          </Typography>

          {selectedAmountPercentRadio == 'percent' ? (
            <FormControl
              className={classes.input}
              error={errors.percentOffError ? true : false}
            >
              <OutlinedInput
                id="percent"
                type="number"
                error={errors.percentOffError ? true : false}
                value={
                  percentOffChange == amountOffChange ? '' : percentOffChange
                }
                onChange={handlePercentOffChange}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
          ) : (
            <FormControl
              className={classes.input}
              error={errors.amountOffError ? true : false}
            >
              <OutlinedInput
                id="amount"
                type="number"
                error={errors.amountOffError ? true : false}
                value={
                  percentOffChange == amountOffChange ? '' : amountOffChange
                }
                onChange={handleAmountOffChange}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
          )}
          <Typography
            variant="body1"
            color={'error'}
            className={classes.errors}
          >
            {errors.percentOffError
              ? errors.percentOffError
              : errors.amountOffError}
          </Typography>
          <FormControl variant="outlined" className={classes.input}>
            <InputLabel>Duration</InputLabel>
            <Select
              id="couponDuration"
              value={durationChange}
              onChange={handleDurationChange}
              label={'Duration'}
            >
              <MenuItem value={CouponDuration.ONCE}>Once</MenuItem>
              <MenuItem value={CouponDuration.REPEATING}>3 Months</MenuItem>
              <MenuItem value={CouponDuration.FOREVER}>Forever</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleCreateCoupon({
                duration: durationChange,
                id: couponName,
                metadata: {},
                percent_off: percentOffChange,
                amount_off: amountOffChange,
                duration_in_months:
                  durationChange == CouponDuration.REPEATING ? 3 : undefined,
              })
            }
          >
            Submit
          </Button>
          <Typography color="error">{createResult.error}</Typography>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" onClick={props.handleModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
}

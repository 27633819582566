import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { Track } from '../../api/tracks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useDownloadMedia } from '../../hooks/useDownloadMedia';
import { Media } from '../../api/Media';
import { useIsMobile } from '../../hooks/isMobile';

export interface DownloadTrackDialogProps extends DialogProps {
  track: Track;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    rows: {},
    row: {
      display: 'flex',
      borderBottom: `1px solid ${theme.palette.borders.dark}`,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);

const orderByName = (a: Media, b: Media): number =>
  a.fileName > b.fileName ? -1 : 1;
const filterMedia = (media: Media): boolean => Boolean(media.link);

export const DownloadTrackDialog = ({
  track,
  ...props
}: DownloadTrackDialogProps) => {
  const classes = useStyles();
  const { downloadMedia, loading } = useDownloadMedia();
  const isMobile = useIsMobile();
  return (
    <Dialog {...props} maxWidth={'sm'} fullWidth fullScreen={isMobile}>
      <DialogTitle>Download media for: {track.name}</DialogTitle>
      <DialogContent>
        <div className={classes.rows}>
          {track.Media.filter(filterMedia)
            .sort(orderByName)
            .map((media) => (
              <div className={classes.row} key={media._id}>
                <Typography variant="subtitle1">
                  {media.fileName}.{media.type}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => downloadMedia(media, track)}
                  disabled={loading}
                  size={isMobile ? 'small' : undefined}
                >
                  Download
                </Button>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => props.onClose?.(e, 'backdropClick')}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

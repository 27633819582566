import { upsertPackCode, deletePackCode, getPackCodes } from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetPackCodesApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({
    apiCall: getPackCodes,
    variables: {},
    ...options,
  });
  return result;
};

export const useUpsertPackCodeApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: upsertPackCode,
    ...options,
  });
  return result;
};

export const useDeletePackCodeApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deletePackCode, ...options });
  return result;
};

import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import HeroImage from '../HeroImage/HeroImage';
import LibraryBrowser from '../LibraryBrowser/LibraryBrowser';
import { Element, scroller } from 'react-scroll';
import { useSearch } from '../../providers/SearchProvider';
import { useHistory, useLocation } from 'react-router';
import { AppRoutes } from '../../constants/routes';
import { useIsMobile } from '../../hooks/isMobile';
import SearchSectionPicker from '../SearchSectionPicker/SearchSectionPicker';
import { TagPicker } from '../TagPicker/TagPicker';
import { useGetTagsApiCall } from '../../api/tags/hooks';
import { Tag } from '../../api/tags';
import { useLibraryBrowserLogic } from '../LibraryBrowser/useLibraryBrowserLogic';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    searchPicker: { margin: theme.spacing(3, 0) },
    picker: { marginLeft: '2%', marginTop: theme.spacing(3) },
  })
);

interface Props {}

const LandingPage = ({}: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (location.search === '?browse') {
      scroller.scrollTo('browse', { duration: 300, delay: 100, smooth: true });
      history.push(AppRoutes.LANDING);
    }
  }, [location.search]);
  return (
    <div>
      <HeroImage />
      <Element name="browse">
        <LibraryBrowser />
      </Element>
    </div>
  );
};

export default LandingPage;

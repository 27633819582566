import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import {
  Favorite,
  FavoriteBorder,
  PlayCircleFilledWhite,
  PauseCircleFilled,
  SkipNext,
  SkipPrevious,
} from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { CustomTheme } from '../../types';
import { ReactComponent as ShareIcon } from './sharebutton.svg';

interface Props {
  onClick: VoidFunction;
  large?: boolean;
  square?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    large: { fontSize: 45 },
    square: { borderRadius: 0 },
    colorOverride: {
      color: theme.palette.common.white,
    },
    iconButton: {},
    whiteColor: {
      color: theme.palette.common.white,
    },
  })
);

const getCommonProps = ({ onClick, large, square }: Props, classes: any) => ({
  classes: {
    root: clsx(
      classes.iconButton,
      large && classes.large,
      square && classes.square
    ),
  },
  color: 'primary' as any,
  onClick,
});

export const PlayButton = (props: Props) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps}>
      <PlayCircleFilledWhite
        fontSize={'inherit'}
        className={classes.whiteColor}
        style={props.large ? { fontSize: 55, marginTop: -5 } : {}}
      />
    </IconButton>
  );
};

export const PauseButton = (props: Props) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps}>
      <PauseCircleFilled
        className={classes.whiteColor}
        fontSize={'inherit'}
        style={props.large ? { fontSize: 55, marginTop: -5 } : {}}
      />
    </IconButton>
  );
};

export const NextButton = (props: Props) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps} className={classes.colorOverride}>
      <SkipNext fontSize={'inherit'} />
    </IconButton>
  );
};

export const PreviousButton = (props: Props) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps} className={classes.colorOverride}>
      <SkipPrevious fontSize={'inherit'} />
    </IconButton>
  );
};

export const ShareButton = (props: Props) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps} color={undefined}>
      <ShareIcon style={{ height: 24, marginBottom: 6 }} />
    </IconButton>
  );
};

export const FavoriteButton = (props: Props & { filled: boolean }) => {
  const classes = useStyles();
  const commonProps = getCommonProps(props, classes);
  return (
    <IconButton {...commonProps} color={props.filled ? 'secondary' : undefined}>
      {props.filled ? <Favorite /> : <FavoriteBorder />}
    </IconButton>
  );
};

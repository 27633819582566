import { Media } from '../api/Media';
import { useGetMediaDownloadLink } from '../api/Media/hooks';
import { Track } from '../api/tracks';
import { AnalyticsService } from '../utils/analytics';
import download from '../utils/download';

export const useDownloadMedia = () => {
  const { runApiCall, ...result } = useGetMediaDownloadLink();
  const handleDownloadMedia = async (media: Media, track: Track) => {
    const link = await runApiCall(media);
    if (!link) {
      console.error('Failed to get a link from download');
      return;
    }
    download(link, `${media.fileName}.${media.type}`);
    AnalyticsService().trackDownload(track);
  };
  return { ...result, downloadMedia: handleDownloadMedia };
};

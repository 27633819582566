import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import { Media } from '../Media';

export type Track = {
  _id: number;
  createdAt: string;
  name: string;
  filename: string;
  categoryID: number;
  description?: string;
  info?: { order?: number };
  active: boolean;
  Media: Media[];
};

export type PurchasedTrack = {
  _id: number;
  createdAt: string;
  userID: string;
  trackID: string;
  txnID: string;
  info?: {};
  active: boolean;
  Track: Track;
};

export type GetTracksResponse = Track[];
export type GetTracksBody = {
  name?: string;
  categoryID?: string | number;
  tags?: string[];
  searchTextItems?: string[];
  trackID?: string | number;
};

export const getTracks = async (
  body: GetTracksBody | undefined,
  options: FetcherRequestConfig
): Promise<GetTracksResponse> => {
  if (body?.trackID) {
    const response = await getTrack({ _id: body.trackID }, options);
    return [response];
  } else {
    const result = await fetcher<GetTracksResponse>(
      `/tracks${body ? buildTrackSearchQuery(body) : ''}`,
      {
        ...options,
        method: HTTPMethod.GET,
      }
    );
    const response = await result.json();
    return response.sort((a, b) =>
      typeof a?.info?.order === 'number' && a.info.order < (b?.info?.order || 0)
        ? -1
        : 1
    );
  }
};

const buildTrackSearchQuery = ({
  name,
  categoryID,
  searchTextItems,
  tags,
}: GetTracksBody) => {
  let query = '?';
  if (name) {
    query += `&name=${encodeURIComponent(name)}`;
  }
  if (categoryID) {
    query += `&categoryID=${encodeURIComponent(categoryID)}`;
  }
  if (searchTextItems) {
    query += `&searchTextItems=${searchTextItems
      .map((item) => encodeURIComponent(item))
      .join(',')}`;
  }
  if (tags) {
    query += `&tags=${tags.join(',')}`;
  }
  if (query.length > 1) {
    query = `/search${query}`;
  }
  return query;
};

export type GetPurchasedTracksResponse = PurchasedTrack[];

export const getPurchasedTracks = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetPurchasedTracksResponse> => {
  const result = await fetcher<GetPurchasedTracksResponse>(`/purchasedTracks`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type GetTrackResponse = Track;
export type GetTrackBody = { _id: string | number };

export const getTrack = async (
  { _id }: GetTrackBody,
  options: FetcherRequestConfig
): Promise<GetTrackResponse> => {
  const result = await fetcher<GetTrackResponse>(`/tracks/${_id}`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type UpdateTrackBody = {
  _id: number;
} & Partial<Track>;

export type UpdateTrackResponse = Track;

export const updateTrack = async (
  { _id, ...body }: UpdateTrackBody,
  options: FetcherRequestConfig
): Promise<UpdateTrackResponse> => {
  const result = await fetcher<UpdateTrackResponse>(`/tracks/${_id}`, {
    ...options,
    method: HTTPMethod.PUT,
    body,
  });
  const response = await result.json();
  return response;
};

export type CreateTrackBody = Omit<Track, 'createdAt' | '_id' | 'Media'>;

export type CreateTrackResponse = Track;

export const createTrack = async (
  body: CreateTrackBody,
  options: FetcherRequestConfig
): Promise<CreateTrackResponse> => {
  const result = await fetcher<CreateTrackResponse>('/tracks', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type AddTagToTrackResponse = null;
export type AddTagToTrackBody = { trackID: string; tagID: string };

export const addTagToTrack = async (
  { trackID, ...body }: AddTagToTrackBody,
  options: FetcherRequestConfig
): Promise<AddTagToTrackResponse> => {
  const result = await fetcher<AddTagToTrackResponse>(
    `/tracks/${trackID}/tags`,
    {
      ...options,
      method: HTTPMethod.POST,
      body,
    }
  );
  return null;
};

export type DeleteTrackBody = {
  _id: number;
};

export type DeleteTrackResponse = null;

export const deleteTrack = async (
  { _id }: DeleteTrackBody,
  options: FetcherRequestConfig
): Promise<DeleteTrackResponse> => {
  await fetcher<DeleteTrackResponse>(`/tracks/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

export type RemoveTagFromTrackBody = {
  trackID: string;
  tagID: string;
};

export type RemoveTagFromTrackResponse = null;

export const removeTagFromTrack = async (
  { trackID, tagID }: RemoveTagFromTrackBody,
  options: FetcherRequestConfig
): Promise<RemoveTagFromTrackResponse> => {
  await fetcher<RemoveTagFromTrackResponse>(`/tracks/${trackID}/${tagID}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

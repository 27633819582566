import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  title: { textAlign: 'left', padding: '1rem 3rem', fontSize: '1.25rem' },
  mainContent: { background: '#D8D8D8' },
}));
export default function Categories() {
  const classes = useStyles();
  useEffect(() => {}, []);
  return (
    <div>
      <div className={classes.title}>Categories</div>
      <div className={classes.mainContent}>Categories content</div>
    </div>
  );
}

import {
  Coupon,
  CouponDuration,
  createCoupon,
  CreateCouponBody,
  deleteCoupon,
  getCoupon,
  GetCouponBody,
  getCoupons,
} from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetCouponsApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({ apiCall: getCoupons, variables: {}, ...options });
  return result;
};

export const useGetCouponApiCall = (
  variables: GetCouponBody,
  options?: UseApiOptions
) => {
  const result = useApiCall({ apiCall: getCoupon, variables, ...options });
  return result;
};

export const useCreateCouponApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall<Coupon, CreateCouponBody>({
    apiCall: createCoupon,
    ...options,
  });

  return result;
};

export const useDeleteCouponApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteCoupon, ...options });
  return result;
};

import Cookies from 'js-cookie';
const TOKEN_KEY = 'token';
export const persistToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const getToken = (): string | null => {
  if (Cookies.get(TOKEN_KEY)) {
    return Cookies.get(TOKEN_KEY) || '';
  }
  return localStorage.getItem(TOKEN_KEY);
};
export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { usePrevious } from '../hooks/usePrevious';
import { FetcherRequestConfig, FetcherRequestOptions } from '../utils/fetcher';

export interface UseApiReturn<T, V> {
  error?: string;
  data?: T;
  loading: boolean;
  refetch: () => Promise<T | null>;
}

export interface UseApiArgs<V, T> {
  apiCall: (body: V, options: FetcherRequestConfig) => Promise<T>;
  variables: V;
}

export interface UseApiOptions {
  skip?: boolean;
}

export const useApiCall = <T, V>({
  apiCall,
  skip,
  variables,
}: UseApiArgs<V, T> & UseApiOptions): UseApiReturn<T, V> => {
  const [controller, setController] = useState<AbortController>(
    new AbortController()
  );
  const [error, setError] = useState<undefined | string>();
  const [data, setData] = useState<undefined | T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasRun, setHasRun] = useState<boolean>(false);
  const previousVariables = usePrevious(variables);

  const makeApiCall = async (controllerOverride?: AbortController) => {
    setError('');
    setLoading(true);
    setHasRun(true);
    let result = null;
    try {
      const response = await apiCall(variables, {
        signal: controllerOverride?.signal || controller.signal,
      });
      setData(response);
      result = response;
      setLoading(false);
    } catch (err) {
      setError(err.message);
      if (err.message.indexOf('user aborted a request') === -1) {
        setLoading(false);
      }
    }
    return result;
  };

  useEffect(() => {
    const variablesAreEqual = _.isEqual(variables, previousVariables);
    // Check if we have not already ran the api call
    if (!skip && !loading) {
      if (!hasRun || !variablesAreEqual) {
        makeApiCall();
        return;
      }
    }
    if (!skip && loading && !variablesAreEqual) {
      controller.abort();
      const newController = new AbortController();
      setController(newController);
      makeApiCall(newController);
      return;
    }
  }, [skip, loading, hasRun, Object.values(variables)]);

  return { error, data, loading, refetch: makeApiCall };
};

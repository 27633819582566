export enum AppRoutes {
  LANDING = '/',
  MY_LIBRARY = '/my-library',
  FAVORITES = '/favorites',
  LOGIN = '/login',
  ADMIN = '/admin',
  FORGOT_PASSWORD = '/login/recovery',
  TERMS = '/terms',
  POLICY = '/privacy',
  SUBSCRIPTION_TERMS = '/subscription-terms',
  PURCHASE_TERMS = '/purchase-terms',
  RESET_PASSWORD = '/login/recovery/update',
  SIGNUP = '/signup',
  LOGOUT = '/logout',
  PLANS = '/plans',
  CHECKOUT_SUCCESS = '/checkout-success',
  CHECKOUT_TRACK_SUCCESS = '/checkout-success/track',
  CHECKOUT_TRACK_PACK_SUCCESS = '/checkout-success/track-pack',
  PURCHASE_TRACK = '/purchase',
  BACKGROUND_PACK = '/background-pack',
  HIGH_END_PACK = '/high-end-pack',
  STOCK_MUSIC_PACK = '/stock-music-pack',
  SETTINGS = '/settings',
  ABOUT_US = '/about',
  CUSTOM = '/custom',
  FAQ = '/faq',
}

import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
  GetCategoryTracksBody,
  getCategoryTracks,
} from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetCategoriesApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({
    apiCall: getCategories,
    variables: {},
    ...options,
  });
  return result;
};

export const useGetCategoryTracksApiCall = (
  variables: GetCategoryTracksBody,
  options?: UseApiOptions
) => {
  const result = useApiCall({
    apiCall: getCategoryTracks,
    variables,
    ...options,
  });
  return result;
};

export const useCreateCategoryApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createCategory,
    ...options,
  });
  return result;
};

export const useUpdateCategoryApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: updateCategory, ...options });
  return result;
};

export const useDeleteCategoryApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteCategory, ...options });
  return result;
};

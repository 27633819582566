import {
  fetcher,
  FetcherRequestConfig,
  FetcherRequestOptions,
  HTTPMethod,
} from '../../utils/fetcher';
import _ from 'lodash';
import { Track } from '../tracks';

export type Category = {
  _id: number;
  createdAt: string;
  name: string;
  description: string;
  info?: { order?: number };
  active: boolean;
  imageURL: string;
  detailImageURL: string;
  detailTitle: string;
  detailDescription: string;
  detailField1: string;
  detailField2: string;
  detailField3: string;
};

export type GetCategoriesResponse = Category[];

export const getCategories = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetCategoriesResponse> => {
  const result = await fetcher<GetCategoriesResponse>(`/categories`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response.sort((a, b) =>
    typeof a?.info?.order === 'number' && a.info.order < (b?.info?.order || 0)
      ? -1
      : 1
  );
};

export type GetCategoryTracksBody = {
  _id: number;
};

export type GetCategoryTracksResponse = Track[];

export const getCategoryTracks = async (
  { _id }: GetCategoryTracksBody,
  options: FetcherRequestConfig
): Promise<GetCategoryTracksResponse> => {
  const result = await fetcher<GetCategoryTracksResponse>(
    `/categories/${_id}/tracks`,
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const tracks = await result.json();
  // Legacy code ordered the tracks at this level, so we will as well

  return _.orderBy(tracks, ['info.order']);
};

export type UpdateCategoryBody = {
  _id: number;
} & Partial<Category>;

export type UpdateCategoryResponse = Category;

export const updateCategory = async (
  { _id, ...body }: UpdateCategoryBody,
  options: FetcherRequestConfig
): Promise<UpdateCategoryResponse> => {
  const result = await fetcher<UpdateCategoryResponse>(`/categories/${_id}`, {
    ...options,
    method: HTTPMethod.PUT,
    body,
  });
  const response = await result.json();
  return response;
};

export type CreateCategoryBody = Omit<Category, 'createdAt' | '_id'>;

export type CreateCategoryResponse = null;

export const createCategory = async (
  body: CreateCategoryBody,
  options: FetcherRequestConfig
): Promise<CreateCategoryResponse> => {
  const result = await fetcher<null>('/categories', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  return null;
};

export type DeleteCategoryBody = {
  _id: number;
};

export type DeleteCategoryResponse = null;

export const deleteCategory = async (
  { _id }: DeleteCategoryBody,
  options: FetcherRequestConfig
): Promise<DeleteCategoryResponse> => {
  await fetcher<DeleteCategoryResponse>(`/categories/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

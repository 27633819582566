import {
  createFavoriteTrack,
  deleteFavoriteTrack,
  getFavoriteTracks,
  GetFavoriteTracksBody,
} from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetFavoriteTracksApiCall = (
  variables?: GetFavoriteTracksBody,
  options?: UseApiOptions
) => {
  const result = useApiCall({
    apiCall: getFavoriteTracks,
    variables: variables || {},
    ...options,
  });
  return result;
};

export const useCreateFavoriteTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createFavoriteTrack,
    ...options,
  });
  return result;
};

export const useDeleteFavoriteTrackApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deleteFavoriteTrack, ...options });
  return result;
};

import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { Track } from '../../api/tracks';
import { SubscriptionStatus } from '../../api/users';
import { useAudio } from '../../providers/AudioProvider';
import { usePurchasedTracks } from '../../providers/PurchasedTracksProvider';
import { useSession } from '../../providers/SessionProvider';
import { isUserSubscribed } from '../../utils/isUserSubscribed';

export interface UseTrackListItemLogicReturn {
  onPlay: VoidFunction;
  onPause: VoidFunction;
  playing: boolean;
  showDownloadButton: boolean;
  prettyIndex: string;
  bpm?: string;
  description: string;
}

const getPrettyIndex = (index: number): string => {
  const newIndex = index + 1;
  if (newIndex < 10) {
    return `0${newIndex}`;
  }
  return newIndex.toString();
};

const getBpmAndDescription = (
  description: string
): { bpm?: string; description: string } => {
  const index = description.toLowerCase().indexOf('bpm');
  if (index >= 0) {
    const bpm = description.slice(0, index + 3).toUpperCase();
    return {
      bpm,
      description,
    };
  } else {
    return { description };
  }
};

export const useTrackListItemLogic = ({
  track,
  index,
}: {
  index: number;
  track: Track;
}): UseTrackListItemLogicReturn => {
  const audio = useAudio();
  const { bpm, description } = getBpmAndDescription(track.description || '');
  const purchasedTracks = usePurchasedTracks();
  const session = useSession();
  const history = useHistory();

  const showDownloadButton = useMemo(() => {
    if (session) {
      return (
        isUserSubscribed(session) ||
        Boolean(
          purchasedTracks.purchasedTracks.find((t) => t._id === track._id)
        )
      );
    } else {
      return false;
    }
  }, [session, purchasedTracks.purchasedTracks]);

  const handlePlay = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?track=${track._id}&name=${track.name.replaceAll(' ', '-')}`,
    });
    if (audio.currentTrack === track) {
      audio.onPlay();
    } else {
      audio.onSelectTrack(track);
    }
  };
  return {
    prettyIndex: getPrettyIndex(index),
    bpm,
    description,
    showDownloadButton,
    playing: audio.playing && audio.currentTrack === track,
    onPlay: handlePlay,
    onPause: audio.onPause,
  };
};

import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CustomTheme } from '../../types';
import { useLibraryBrowserLogic } from './useLibraryBrowserLogic';
import TextListItem from '../TextListItem/TextListItem';
import { Tag } from '../../api/tags';
import { Category } from '../../api/categories';
import { CategoryList } from '../CategoryList/CategoryList';
import { TrackList } from '../TrackList/TrackList';
import {
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FullScreenSearch } from '../FullScreenSearch/FullScreenSearch';
import { useSearch } from '../../providers/SearchProvider';
import { useIsMobile } from '../../hooks/isMobile';
import SearchSectionPicker from '../SearchSectionPicker/SearchSectionPicker';
import { TagPicker } from '../TagPicker/TagPicker';
import { CategoryPicker } from '../CategoryPicker/CategoryPicker';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    searchPicker: { margin: theme.spacing(3, 0) },
    picker: { marginLeft: '2%', marginTop: theme.spacing(3) },
    noResults: {
      textAlign: 'center',
    },
    container: {
      '& > div': { padding: theme.spacing(3, 2) },
      height: '80vh',
      background: theme.palette.background.default,
      overflow: 'auto',
    },
    progress: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      height: 8,
    },
    masterList: {
      borderRight: `1px solid ${theme.palette.borders.light}`,
      background: theme.palette.background.paper,
    },
    childList: {
      maxWidth: 175,
      overflow: 'auto',
      background: theme.palette.background.paper,
    },
    separator: {
      borderTop: `2px solid ${theme.palette.borders.dark}`,
      padding: theme.spacing(1, 0),
    },
    contentContainer: {
      position: 'relative',
      flex: 10,
      overflow: 'auto',
    },
  })
);

export interface LibraryBrowserProps {}

const LibraryBrowser = React.memo(({}: LibraryBrowserProps) => {
  const classes = useStyles();
  const {
    tagCategories,
    childListItems,
    selectedTags,
    selectedTagParent,
    onSelectCategory,
    onSelectCategoryParent,
    onSelectTag,
    onSelectTagParent,
    selectedCategory,
    tracks,
    tracksLoading,
    categories,
    showUrlTrack,
    onClearSelectedTags,
  } = useLibraryBrowserLogic();
  const search = useSearch();
  const isMobile = useIsMobile();

  return (
    <>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.searchPicker}>
            <SearchSectionPicker />
          </div>
        )}
        <div className={classes.picker}>
          {selectedTagParent !== null && (
            <TagPicker
              tags={childListItems.map(({ item }) => item as Tag)}
              selectedTags={selectedTags}
              onSelectTag={onSelectTag}
              title={selectedTagParent}
              onClearSelected={onClearSelectedTags}
            />
          )}
          {selectedTagParent === null && (
            <CategoryPicker
              categories={childListItems.map(({ item }) => item as Category)}
              selectedCategory={selectedCategory}
              onSelectCategory={onSelectCategory}
            />
          )}
        </div>
        <div className={classes.contentContainer}>
          {tracksLoading ? (
            <LinearProgress
              className={classes.progress}
              variant="indeterminate"
            />
          ) : (
            <>
              {tracks.length === 0 && (
                <div className={classes.noResults}>
                  {/* <Typography variant="h4">No Results</Typography> */}
                </div>
              )}
              <TrackList tracks={tracks} />
            </>
          )}
        </div>
      </div>
      <FullScreenSearch
        {...{
          open: search.fullScreenSearchOpen,
          onClose: search.onCloseFullScreenSearch,
          selectedCategory,
          tagCategories,
          childListItems,
          selectedTags,
          selectedTagParent,
          categories,
          onSelectCategory,
          onSelectCategoryParent,
          onSelectTag,
          onSelectTagParent,
        }}
      />
    </>
  );
});

export default LibraryBrowser;

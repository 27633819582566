import { InputAdornment, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { useGetTagsApiCall } from '../../api/tags/hooks';
import { useSearch } from '../../providers/SearchProvider';
import { CustomTheme } from '../../types';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { Tag } from '../../api/tags';
import { TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { useIsMobile } from '../../hooks/isMobile';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    tag: {
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    inputRoot: {
      '&&:after': { borderBottom: 'none !important' },
      '&&:hover:after': { borderBottom: 'none !important' },
      '&&:focus:after': { borderBottom: 'none !important' },
      '&&:before': { borderBottom: 'none !important' },
      '&&:hover:before': { borderBottom: 'none !important' },
      '&&:focus:before': { borderBottom: 'none !important' },
      '& input': {
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
          fontSize: 'initial',
        },
      },
    },
    search: {
      color: theme.palette.text.secondary,
      margin: theme.spacing(1, 0, 0, 1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1.5, 0, 0, 1),
      },
    },
    deleteIcon: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      '&:hover': {
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
  })
);
export interface SearchBarProps {}

export const SearchBar = ({}: SearchBarProps) => {
  const classes = useStyles();
  const search = useSearch();
  const allTags = useGetTagsApiCall();
  const isMobile = useIsMobile;
  const handleChange: AutocompleteProps<
    Tag | string,
    any,
    any,
    any
  >['onChange'] = (_e, value) => {
    if (Array.isArray(value)) {
      search.onUpdateTags(
        (value.filter((v) => typeof v !== 'string') as Tag[]) || []
      );
      search.onUpdateSearchTextItems(
        (value.filter((v) => typeof v === 'string') as string[]) || []
      );
    }
  };

  return (
    <div className={classes.container}>
      <Search color="inherit" className={classes.search} />
      <Autocomplete
        ChipProps={{
          deleteIcon: (
            <Close color={'inherit'} className={classes.deleteIcon} />
          ),
        }}
        multiple
        freeSolo
        id="tags-standard"
        fullWidth
        value={[...search.tags, ...search.searchTextItems]}
        onChange={handleChange}
        options={allTags.data || []}
        getOptionLabel={(option: Tag | string) =>
          typeof option === 'string' ? option : option.name
        }
        loading={allTags.loading}
        loadingText="Loading..."
        defaultValue={[]}
        classes={{ tag: classes.tag, inputRoot: classes.inputRoot }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Genre, Mood, Instruments, etc."
          />
        )}
      />
    </div>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@material-ui/core';
import {
  Button,
  Chip,
  Dialog,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ChevronLeft, Close } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import { Category, GetCategoriesResponse } from '../../api/categories';
import { Tag } from '../../api/tags';
import { useGetTagsApiCall } from '../../api/tags/hooks';
import { CustomTheme } from '../../types';
import { MOBILE_HEADER_HEIGHT } from '../Header/Header';
import { ChildListItem } from '../LibraryBrowser/useLibraryBrowserLogic';
import { SearchBar } from '../SearchBar/SearchBar';
import TextListItem from '../TextListItem/TextListItem';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    dialogPaper: {
      [theme.breakpoints.only('xs')]: {
        height: `calc(96vh - 85px)`,
        top: '30px',
      },
    },
    backdropRoot: {
      backgroundColor: '#484143',
      opacity: '0.95 !important', //There is a hard coded style on the backdrop component for opacity
      marginTop: MOBILE_HEADER_HEIGHT,
    },
    headerRow: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    back: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    detailsRoot: { flexWrap: 'wrap' },
    container: {
      [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(1),
      },
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    parentList: {
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    childList: {
      flex: 1,
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.borders.light}`,
      overflowY: 'scroll',
      '& > *': {
        border: `1px solid ${theme.palette.borders.dark}`,
      },
    },
    search: {
      margin: theme.spacing(1),
      border: `1px solid ${theme.palette.borders.dark}`,
      borderRadius: 50,
      paddingBottom: theme.spacing(1),
    },
  })
);
export interface FullScreenSearchProps {
  open: boolean;
  tagCategories: string[];
  childListItems: ChildListItem[];
  selectedTags: Tag[];
  selectedCategory: Category | null;
  selectedTagParent: string | null;
  categories: GetCategoriesResponse | undefined;
  onSelectCategoryParent: () => void;
  onSelectCategory: (category: Category) => void;
  onSelectTagParent: (parent: string) => void;
  onSelectTag: (tag: Tag) => void;
  onClose: VoidFunction;
}

export const FullScreenSearch = ({
  open,
  onClose,
  selectedCategory,
  tagCategories,
  childListItems,
  selectedTags,
  selectedTagParent,
  categories,
  onSelectCategory,
  onSelectCategoryParent,
  onSelectTag,
  onSelectTagParent,
}: FullScreenSearchProps) => {
  const classes = useStyles();
  const tags = useGetTagsApiCall();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      maxWidth={'md'}
      fullWidth
      BackdropProps={{ classes: { root: classes.backdropRoot } }}
    >
      <div className={classes.container}>
        <div className={classes.headerRow}>
          <div>
            <Typography
              onClick={onClose}
              color="textSecondary"
              className={classes.back}
            >
              <ChevronLeft /> Back
            </Typography>
          </div>

          <Typography variant="h6">Filters</Typography>
          <div>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        <div className={classes.search}>
          <SearchBar />
        </div>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Apply
        </Button>
        <div className={classes.parentList}>
          {tagCategories.map((tagCategory) => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{tagCategory}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detailsRoot}>
                {tags.data
                  ?.filter((tag) => tag.category === tagCategory)
                  .map((tag) => (
                    <Chip
                      label={tag.name}
                      key={tag._id}
                      onClick={() => onSelectTag(tag)}
                      onDelete={
                        Boolean(selectedTags.find((t) => t._id === tag._id))
                          ? () => onSelectTag(tag)
                          : undefined
                      }
                      color={
                        Boolean(selectedTags.find((t) => t._id === tag._id))
                          ? undefined
                          : 'primary'
                      }
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

import { createPlan, deletePlan, getPlans } from '.';
import { useActionApiCall } from '../useActionApiCall';
import { useApiCall, UseApiOptions } from '../useApiCall';

export const useGetPlansApiCall = (options?: UseApiOptions) => {
  const result = useApiCall({ apiCall: getPlans, variables: {}, ...options });
  return result;
};

export const useCreatePlanApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({
    apiCall: createPlan,
    ...options,
  });
  return result;
};

export const useDeletePlanApiCall = (options?: UseApiOptions) => {
  const result = useActionApiCall({ apiCall: deletePlan, ...options });
  return result;
};

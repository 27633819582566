import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import { AuthResponse } from '../auth';

export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
}

export type User = {
  _id: number;
  createdAt: string;
  email: string;
  industry: string | null;
  info?: {
    purchased?: boolean;
    subscription_level?: string;
  };
  notifications: boolean;
  profile: {
    name: string;
    role: UserRole;
  };
  provider: string;
  role: UserRole;
  subscription_end_date?: string;
  subscription_start_date?: string;
  subscription_status?: SubscriptionStatus;
  subscription_type?: string;
  zip?: string;
};

export type GetMeResponse = User;

export type GetUsersResponse = {
  users: User[];
  pageInfo: { total: number; ofset: number; hasNextPage: boolean };
};

export const META_SUBSCRIPTION_TYPES = [
  'Custom',
  'Premium',
  'Small Business',
  'Year of Music',
];

export const getMe = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetMeResponse> => {
  const result = await fetcher<GetMeResponse>('/users/me', {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type GetUsersVariables = {
  offset?: number;
  name?: string;
  email?: string;
  subscription_type?: string;
  createdAt?: string;
  subscription_start_date?: string;
  subscription_end_date?: string;
  _id: number;
};

export type GetSearchUsersVariables = Omit<GetUsersVariables, '_id'>;

export const getUsers = async (
  { offset = 0, name, email, subscription_type }: GetSearchUsersVariables,
  options: FetcherRequestConfig
): Promise<GetUsersResponse> => {
  let queryString = `?offset=${offset}`;
  if (name) {
    queryString += `&name=${name}`;
  }
  if (email) {
    queryString += `&email=${email}`;
  }
  if (subscription_type) {
    queryString += `&subscription_type=${subscription_type}`;
  }
  const result = await fetcher<GetUsersResponse>(`/users${queryString}`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type UpdateUserBody = {
  _id?: number | 'me';
} & Partial<User>;

export type UpdateUserResponse = User;

export const updateUser = async (
  { _id, ...body }: UpdateUserBody,
  options: FetcherRequestConfig
): Promise<UpdateUserResponse> => {
  const result = await fetcher<UpdateUserResponse>(`/users/${_id}`, {
    ...options,
    method: HTTPMethod.PUT,
    body,
  });
  const response = await result.json();
  return response;
};

export type CreateUserBody = {
  email: string;
  industry?: string;
  name: string;
  notifications: boolean;
  password: string;
  terms: boolean;
  zip: string;
};

export type CreateUserResponse = AuthResponse;

export const createUser = async (
  body: CreateUserBody,
  options: FetcherRequestConfig
): Promise<CreateUserResponse> => {
  const result = await fetcher<CreateUserResponse>('/users', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type DeleteUserBody = {
  _id: number;
};

export type DeleteUserResponse = null;

export const deleteUser = async (
  { _id }: DeleteUserBody,
  options: FetcherRequestConfig
): Promise<DeleteUserResponse> => {
  await fetcher<DeleteUserResponse>(`/users/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};

export type UpdateUserPasswordBody = {
  _id: number;
  oldPassword: string;
  newPassword: string;
};

export type UpdateUserPasswordResponse = true;

export const updateUserPassword = async (
  { _id, ...body }: UpdateUserPasswordBody,
  options: FetcherRequestConfig
): Promise<UpdateUserPasswordResponse> => {
  const result = await fetcher<UpdateUserResponse>(`/users/${_id}/password`, {
    ...options,
    method: HTTPMethod.PUT,
    body,
  });
  return true;
};

import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSession } from '../../providers/SessionProvider';
import { CustomTheme } from '../../types';
import { List, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  Group,
  Category,
  Label,
  Subscriptions,
  Audiotrack,
  LocalOffer,
  Assessment,
  Settings,
  VpnKey,
} from '@material-ui/icons';
import Sticky from 'react-stickynode';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    navigation: {
      marginTop: '-0.5rem',
      height: '100%',
      borderRight: 'solid black',
      width: '25vw',
    },

    listItem: {
      marginLeft: '0',
      borderBottom: 'solid black',
      textAlign: 'center',
    },
    listItemIcon: { marginRight: '-3rem', marginLeft: '1rem' },

    selected: { color: 'black' },
  })
);

const menuItems = [
  {
    listIcon: <Group />,
    listText: 'Users',
    path: '/admin/users',
  },
  {
    listIcon: <Category />,
    listText: 'Categories',
    path: '/admin/categories',
  },
  {
    listIcon: <Label />,
    listText: 'Tags',
    path: '/admin/tags',
  },
  {
    listIcon: <Subscriptions />,
    listText: 'Plans',
    path: '/admin/plans',
  },
  {
    listIcon: <Audiotrack />,
    listText: 'Tracks',
    path: '/admin/tracks',
  },
  {
    listIcon: <LocalOffer />,
    listText: 'Coupons',
    path: '/admin/coupons',
  },
  {
    listIcon: <Assessment />,
    listText: 'Reports',
    path: '/admin/reports',
  },
  {
    listIcon: <Settings />,
    listText: 'Site Config',
    path: '/admin/site-config',
  },
  {
    listIcon: <VpnKey />,
    listText: 'Pack Codes',
    path: '/admin/pack-codes',
  },
];

const AdminNav = () => {
  const classes = useStyles();
  const session = useSession();
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState<number>();

  const handleClick = (selectedPageIndex: number) =>
    setSelectedPage(selectedPageIndex);

  return (
    <div className={classes.navigation}>
      <Sticky>
        <List>
          {menuItems.map((lsItem, key) => (
            <MenuItem
              className={classes.listItem}
              button
              key={key}
              component={Link}
              to={lsItem.path}
              onClick={() => handleClick(key)}
              selected={selectedPage === key}
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {lsItem.listIcon}
              </ListItemIcon>
              <ListItemText primary={lsItem.listText} />
            </MenuItem>
          ))}
        </List>
      </Sticky>
    </div>
  );
};
export default AdminNav;

import { fetcher, FetcherRequestConfig, HTTPMethod } from '../../utils/fetcher';
import _ from 'lodash';

export enum PackCodeBundle {
  HIGH_END = 'highEndMusicCollection',
  VIDEOGRAPHER = 'videographerCollection',
  STOCK_MUSIC = 'stockMusicProBundleCollection',
}

export type PackCode = {
  _id: number;
  createdAt: string;
  bundle: PackCodeBundle;
  info: {};
  active: boolean;
  code: string;
};

export type GetPackCodesResponse = PackCode[];

export const getPackCodes = async (
  variables: {},
  options: FetcherRequestConfig
): Promise<GetPackCodesResponse> => {
  const result = await fetcher<GetPackCodesResponse>(`/packCodes`, {
    ...options,
    method: HTTPMethod.GET,
  });
  const response = await result.json();
  return response;
};

export type GetPackCodeBody = { code: number };
export type GetPackCodeResponse = PackCode[];

export const getPackCode = async (
  { code }: GetPackCodeBody,
  options: FetcherRequestConfig
): Promise<GetPackCodeResponse> => {
  const result = await fetcher<GetPackCodeResponse>(
    `/packCodes/${encodeURIComponent(code)}`,
    {
      ...options,
      method: HTTPMethod.GET,
    }
  );
  const response = await result.json();
  return response;
};

export type UpsertPackCodeBody = {
  code: string;
  _id?: number;
  bundle?: string;
};

export type UpsertPackCodeResponse = boolean;

export const upsertPackCode = async (
  body: UpsertPackCodeBody,
  options: FetcherRequestConfig
): Promise<UpsertPackCodeResponse> => {
  const result = await fetcher<UpsertPackCodeResponse>('/packCodes', {
    ...options,
    method: HTTPMethod.POST,
    body,
  });
  const response = await result.json();
  return response;
};

export type DeletePackCodeBody = {
  _id: number;
};

export type DeletePackCodeResponse = null;

export const deletePackCode = async (
  { _id }: DeletePackCodeBody,
  options: FetcherRequestConfig
): Promise<DeletePackCodeResponse> => {
  await fetcher<DeletePackCodeResponse>(`/packCodes/${_id}`, {
    ...options,
    method: HTTPMethod.DELETE,
  });
  return null;
};
